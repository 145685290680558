import React from "react";
import "./Contactus.css";
import worldmap from "./World Map.png";

const Contactus = () => {
  return (
    <>
      <div className="desktop_div">
        <div className="Contactus_page_main">
          <div className="Contactus_page_main_contact">
            <h2>Contact Us</h2>
            <p>
              Reach out to us for personalized support and discover how our
              solutions can transform your travel business
            </p>
          </div>
          <div className="Contactus_page_main_image">
            <img src={worldmap} alt="worldmap" />
          </div>
          <div className="Contactus_page_main_adresses">
            <div className="Contactus_page_main_adresses_inner">
              <div className="Contactus_page_main_adresses_inner_content">
                <h3>United States</h3>
                <p>
                  2803 Philadelphia Pike B # 4271<br></br>
                  Claymont, DE 19703
                </p>
              </div>
            </div>
            <div className="Contactus_page_main_adresses_inner">
              <div className="Contactus_page_main_adresses_inner_content">
                <h3>India</h3>
                <p>
                  16 Community Center, East<br></br>
                  Kailash, Delhi, 110065
                </p>
              </div>
            </div>
            <div className="Contactus_page_main_adresses_inner">
              <div className="Contactus_page_main_adresses_inner_content">
                <h3>Indonesia</h3>
                <p>
                  Puri Gong, Jimbaran, Kuta<br></br>
                  Bali, 80361
                </p>
              </div>
            </div>
          </div>
          <div className="Contactus_page_main_button">
            <button>Contact Us</button>
          </div>
        </div>
      </div>
      <div className="mobile_div">
        <div className="contactus_page_mobile_main">
          <div className="contactus_page_mobile_inner">
            <div className="contactus_page_mobile_inner_hero">
              <div className="contactus_page_mobile_inner_hero_solution">
                <div className="contactus_page_mobile_inner_hero_solution_text_box">
                  <h2>Contact us</h2>
                  <p>
                    Reach out to us for personalized support and discover how
                    our solutions can transform your travel business
                  </p>
                </div>
              </div>
              <div className="contactus_page_mobile_inner_hero_worldmap">
                <img src={worldmap} alt="" />
              </div>
            </div>
            <div className="contactus_page_mobile_inner_location">
              <div className="contactus_page_mobile_inner_location_inner">
                <div className="contactus_page_mobile_inner_location_inner_adress">
                  <h2>United States</h2>
                  <p>
                    2803 Philadelphia Pike B # 4271<br></br>
                    Claymont, DE 19703
                  </p>
                </div>
              </div>
              <div className="contactus_page_mobile_inner_location_inner">
                <div className="contactus_page_mobile_inner_location_inner_adress">
                  <h2>India</h2>
                  <p>
                    16 Community Center, East<br></br>
                    Kailash, Delhi, 110065
                  </p>
                </div>
              </div>
              <div className="contactus_page_mobile_inner_location_inner">
                <div className="contactus_page_mobile_inner_location_inner_adress">
                  <h2>Indonesia</h2>
                  <p>
                    Puri Gong, Jimbaran, Kuta<br></br>
                    Bali, 80361
                  </p>
                </div>
              </div>
            </div>
            <div className="contactus_page_mobile_inner_button">
              <button>Contact us</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
