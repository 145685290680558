import React from "react";
import "./Homepage.css";
import Group from "./Group.png";
import First from "./first.png";
import Second from "./second.png";
import Third from "./third.png";
import Fourth from "./fourth.png";
import { Link } from "react-router-dom";

const Homepage = () => {
  return (
    <>
      <div className="desktop_div">
        <div className="main_section">
          <div className="top_heading">
            <h3>Activitybeds</h3>
          </div>
          <div className="bottom_heading">
            <h2>Pioneering Travel Solutions</h2>
          </div>
          <div className="bottom_paragraph">
            <p>High tech horizons: Where innovation fuels your business</p>
          </div>
        </div>
        <div className="main_section_img">
          <img src={Group} alt="Group" />
        </div>
        <div className="about_us">
          <div className="about_us_inner">
            <div className="about_us_content">
              <h3>About Us</h3>
              <h2>Explore our Essence</h2>
              <p>
                Delve into the core of who we are, uncovering the essence that
                drives our purpose and passion
              </p>
              <Link to="/about-us">
                <button className="my_button">Learn More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="mid_sec_outer">
          <div className="mid_sec_inner">
            <div className="mid_sec_inner_left">
              <h2>Agent Marketplace</h2>
              <p>
                Connect with global travel agents to expand your reach and boost
                sales<br></br> through our innovative Agent Marketplace platform
              </p>
              <Link to="/agent-marketplace">
                <button className="mid_sec_button">Learn More</button>
              </Link>
            </div>
            <div className="mid_sec_inner_right">
              <img src={First} alt="Group" />
            </div>
          </div>
          <div className="mid_sec_inner">
            <div className="mid_sec_inner_right">
              <img src={Second} alt="Group" />
            </div>
            <div className="mid_sec_inner_left">
              <h2>Channel Manager</h2>
              <p>
                Streamline your travel distribution with our Channel Manager,
                ensuring seamless<br></br> inventory control, real-time updates,
                and maximized revenue across all channels
              </p>
              <Link to="/channel-manager">
                <button className="mid_sec_button">Learn More</button>
              </Link>
            </div>
          </div>
          <div className="mid_sec_inner">
            <div className="mid_sec_inner_left">
              <h2>AI Trip Planner</h2>
              <p>
                Experience hassle-free travel planning with our AI Trip Planner
                service, tailored to<br></br> create unforgettable journeys just
                for your customers
              </p>
              <Link to="/aitripplanner">
                <button className="mid_sec_button">Learn More</button>
              </Link>
            </div>
            <div className="mid_sec_inner_right">
              <img src={Third} alt="Group" />
            </div>
          </div>
          <div className="mid_sec_inner">
            <div className="mid_sec_inner_right">
              <img src={Fourth} alt="Group" />
            </div>
            <div className="mid_sec_inner_left">
              <h2>Payment Solutions</h2>
              <p>
                Streamline transactions with our secure, efficient Payment
                Solution, integrating<br></br> seamlessly with your systems for
                smooth, reliable processing
              </p>
              <Link to="/payment-solutions">
                <button className="mid_sec_button">Learn More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="contect_us_main">
          <div className="contect_us_inner">
            <h3>contact us</h3>
            <h2>Cruise with us on our travel tech escapades</h2>
            <p>
              Ready to boost your bottom line with our winning <br></br>
              strategies? Reach out to us now for tailored solutions
            </p>
            <Link to="/contact-us">
            <button className="contect_us_button">Contact Us</button></Link>
          </div>
        </div>
      </div>
      <div className="mobile_div">
        <div className="mobile_hero">
          <div className="mobile_hero_top">
            <div className="mobile_hero_top_main">
              <h3>Activitybeds</h3>
              <div className="mobile_hero_top_bottom">
                <h2>Pioneering Travel Solutions</h2>
                <p>High tech horizons: Where innovation fuels your business</p>
              </div>
            </div>
          </div>
          <div className="mobile_hero_bottom">
            <img src={Group} alt="" />
          </div>
        </div>
        <div className="mobile_aboutus_main">
          <div className="mobile_aboutus_inner">
            <div className="mobile_aboutus_content">
              <h3>About Us</h3>
              <div className="mobile_aboutus_inner_content">
                <h2>Explore our Essence</h2>
                <p>
                  Delve into the core of who we are, uncovering the essence that
                  drives our purpose and passion
                </p>
              </div>
              <Link to="/about-us">
                <button>Learn More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="mobile_home_fourbox_main">
          <div className="mobile_home_fourbox_inner">
            <div className="mobile_home_fourbox_top">
              <div className="mobile_home_fourbox_top_inner">
                <div className="mobile_home_fourbox_top_inner_content">
                  <h2>Agent Marketplace</h2>
                  <p>
                    Connect with global travel agents to expand your reach and
                    boost sales through our innovative Agent Marketplace
                    platform
                  </p>
                </div>
                <div className="mobile_home_fourbox_top_inner_button">
                  <Link to="/agent-marketplace">
                    <button>Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="mobile_home_fourbox_bottom">
              <img src={First} alt="" />
            </div>
          </div>
          <div className="mobile_home_fourbox_inner">
            <div className="mobile_home_fourbox_top">
              <div className="mobile_home_fourbox_top_inner">
                <div className="mobile_home_fourbox_top_inner_content">
                  <h2>Channel Manager</h2>
                  <p>
                    Streamline your travel distribution with our Channel
                    Manager, ensuring seamless inventory control, real-time
                    updates, and maximized revenue across all channels
                  </p>
                </div>
                <div className="mobile_home_fourbox_top_inner_button">
                  <Link to="/channel-manager">
                    <button>Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="mobile_home_fourbox_bottom">
              <img src={Second} alt="" />
            </div>
          </div>
          <div className="mobile_home_fourbox_inner">
            <div className="mobile_home_fourbox_top">
              <div className="mobile_home_fourbox_top_inner">
                <div className="mobile_home_fourbox_top_inner_content">
                  <h2>AI Trip Planner</h2>
                  <p>
                    Experience hassle-free travel planning with our AI Trip
                    Planner service, tailored to create unforgettable journeys
                    just for your customers
                  </p>
                </div>
                <div className="mobile_home_fourbox_top_inner_button">
                  <Link to="/aitripplanner">
                    <button>Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="mobile_home_fourbox_bottom">
              <img src={Third} alt="" />
            </div>
          </div>
          <div className="mobile_home_fourbox_inner">
            <div className="mobile_home_fourbox_top">
              <div className="mobile_home_fourbox_top_inner">
                <div className="mobile_home_fourbox_top_inner_content">
                  <h2>Payment Solutions</h2>
                  <p>
                    Streamline transactions with our secure, efficient Payment
                    Solution, integrating seamlessly with your systems for
                    smooth, reliable processing
                  </p>
                </div>
                <div className="mobile_home_fourbox_top_inner_button">
                  <Link to="/payment-solutions">
                    <button>Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="mobile_home_fourbox_bottom">
              <img src={Fourth} alt="" />
            </div>
          </div>
        </div>
        <div className="mobile_contactus_main">
          <div className="mobile_contactus_inner">
            <div className="mobile_contactus_content">
              <h3>contact us</h3>
              <h2>Cruise with us on our travel tech escapades</h2>
            </div>
            <p>
              Ready to boost your bottom line with our winning strategies? Reach
              out to us now for tailored solutions
            </p>
            <Link to="/contact-us"><button>Learn More</button></Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
