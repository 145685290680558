import React from "react";
import "./Aboutus.css";
import { Link } from "react-router-dom";
import worldcomp from "./Computer-World-Map-Location 1.png";
import twosecone from "./Business-Deal-4 1.png";
import twosectwo from "./Business-Deal-5 1.png";

const Aboutus = () => {
  return (
    <>
      <div className="desktop_div">
        <div className="aboutpage_main">
          <div className="aboutpage_main_top">
            <h3>About Us</h3>
            <h2>Who We Are: Innovators in travel technology</h2>
            <p>
              Activitybeds is a global B2B marketplace bridging the gap between
              travel suppliers and travel agents. As an intermediary, we source
              the content,<br></br> aggregate it & present it on our platform,
              thereby distributing it to the global network of travel trade
              partners who further sell it to their customers.<br></br>
              From individual to multi-national operators, Activitybeds is your
              trusted platform to grow your business and share your passion with
              the world
            </p>
          </div>
          <div className="aboutpage_main_bottom">
            <img src={worldcomp} alt="" />
          </div>
        </div>
        <div className="aboutpage_twosec">
          <div className="aboutpage_twosec_top">
            <div className="aboutpage_twosec_top_left">
              <h3>If you are a Travel Agent:</h3>
              <p>
                We offer the services to our travel agents in an “A <br></br>la
                Carte” fashion, where the agent can choose<br></br> the
                product(s) individually, thus creating the <br></br>bookings
                more transparent and easier to track. <br></br>We offer a
                diverse portfolio of activities/transfers<br></br> along with
                the accommodation options, which<br></br> the travel trade
                partners can avail and get real
                <br></br> time confirmation with just a click.
              </p>
            </div>
            <div className="aboutpage_twosec_top_right">
              <img src={twosecone} alt="" />
            </div>
          </div>
          <div className="aboutpage_twosec_bottom">
            <div className="aboutpage_twosec_bottom_left">
              <img src={twosectwo} alt="" />
            </div>
            <div className="aboutpage_twosec_bottom_right">
              <h3>If you are a Travel Supplier:</h3>
              <p>
                We offer the services to travel suppliers, helping<br></br> them
                grow their businesses exponentially by<br></br> providing them
                with the opportunity to reach out <br></br>to the travel
                partners worldwide. Also giving <br></br>access to the
                market-specific insights thus <br></br>helping your products to
                stand out from the rest.
              </p>
            </div>
          </div>
        </div>
        <div className="aboutpage_ease">
          <h3>
            Ease into your next adventure: <br></br>Reach out to us and get
            started!
          </h3>
          <Link to="/contact-us">
              <button>Contact Us</button></Link>
        </div>
      </div>
      <div className="mobile_div">
        <div className="aboutpage_mobile_main">
          <div className="aboutpage_mobile_hero">
            <div className="aboutpage_mobile_hero_inner">
              <div className="aboutpage_mobile_hero_inner_solution">
                <h3>About US</h3>
                <div className="aboutpage_mobile_hero_inner_solution_text_box">
                  <h2>Who We Are: Innovators in travel technology</h2>
                  <p>
                    Activitybeds is a global B2B marketplace bridging the gap
                    between travel suppliers and travel agents. As an
                    intermediary, we source the content, aggregate it & present
                    it on our platform, thereby distributing it to the global
                    network of travel trade partners who further sell it to
                    their customers. From individual to multi-national
                    operators, Activitybeds is your trusted platform to grow
                    your business and share your passion with the world
                  </p>
                </div>
              </div>
              <div className="aboutpage_mobile_hero_inner_image">
                <img src={worldcomp} alt="" />
              </div>
            </div>
          </div>
          <div className="aboutpage_mobile_features">
            <div className="aboutpage_mobile_features_inner">
              <div className="aboutpage_mobile_features_inner_content">
                <h2>If you are a Travel Agent:</h2>
                <p>
                  We offer the services to our travel agents in an “A la Carte”
                  fashion, where the agent can choose the product(s)
                  individually, thus creating the bookings more transparent and
                  easier to track. We offer a diverse portfolio of
                  activities/transfers along with the accommodation options,
                  which the travel trade partners can avail and get real time
                  confirmation with just a click.
                </p>
              </div>
              <div className="aboutpage_mobile_features_inner_image">
                <img src={twosecone} alt="" />
              </div>
            </div>
            <div className="aboutpage_mobile_features_inner">
              <div className="aboutpage_mobile_features_inner_content">
                <h2>If you are a Travel Supplier:</h2>
                <p>
                  We offer the services to travel suppliers, helping them grow
                  their businesses exponentially by providing them with the
                  opportunity to reach out to the travel partners worldwide.
                  Also giving access to the market-specific insights thus
                  helping your products to stand out from the rest.
                </p>
              </div>
              <div className="aboutpage_mobile_features_inner_image">
                <img src={twosectwo} alt="" />
              </div>
            </div>
          </div>
          <div className="agentmarketplace_mobile_cta">
            <h2>
              Ease into your next adventure: Hit the road tech style with us!
            </h2>
            <Link to="/contact-us">
              <button>Contact Us</button></Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
