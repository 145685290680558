import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import "./Footor.css";
import logo from "./activitybedlogo.png";
import arrowdown from "./arrowdown.svg";
import uparrow from "./Uparrow.svg";
import insta from "./insta.svg";
import twitter from "./twitter.png";
import facebook from "./facebook.svg";
import linkedin from "./linkedin.png";
import youtube from "./youtube.png";

const MobileFooter = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  const [openDropdown, setOpenDropdown] = useState({
    solutions: false,
    resources: false,
    company: false,
  });

  const [isIndia, setIsIndia] = useState(false);

  const contentRefs = {
    solutions: useRef(null),
    resources: useRef(null),
    company: useRef(null),
  };

  const handleToggle = (section) => {
    setOpenDropdown((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  useEffect(() => {
    Object.keys(contentRefs).forEach((key) => {
      if (openDropdown[key]) {
        contentRefs[key].current.style.maxHeight =
          contentRefs[key].current.scrollHeight + "px";
      } else {
        contentRefs[key].current.style.maxHeight = "0";
      }
    });
  }, [openDropdown]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get(
          "https://ipinfo.io/json?token=f6a7bdb81783b0"
        );
        if (response.data && response.data.country === "IN") {
          setIsIndia(true);
        }
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    fetchLocation();
  }, []);

  return (
    <div className="mobile_footer">
      <div className="mobile_footer_inner">
        <div className="mobile_footer_inner_top">
          <div className="mobile_footer_inner_top_logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
            <p>
              High-Tech Horizons: Where innovation
              <br /> fuels your business
            </p>
          </div>
          <div className="footer_devider"></div>

          <div
            className="footer_menu_item"
            onClick={() => handleToggle("solutions")}
          >
            <h3>Solutions</h3>
            <img
              src={openDropdown.solutions ? uparrow : arrowdown}
              alt="toggle"
            />
          </div>
          <div className="footer_dropdown" ref={contentRefs.solutions}>
            <Link
              onClick={() => handleToggle("solutions")}
              to="/agent-marketplace"
            >
              <h3>Agent Marketplace</h3>
            </Link>
            <Link
              onClick={() => handleToggle("solutions")}
              to="/channel-manager"
            >
              <h3>Channel Manager</h3>
            </Link>
            <Link onClick={() => handleToggle("solutions")} to="/aitripplanner">
              <h3>AI Trip Planner</h3>
            </Link>
            <Link
              onClick={() => handleToggle("solutions")}
              to="/payment-solutions"
            >
              <h3>Payment Solutions</h3>
            </Link>
          </div>
          <div className="footer_devider"></div>

          <div
            className="footer_menu_item"
            onClick={() => handleToggle("resources")}
          >
            <h3>Resources</h3>
            <img
              src={openDropdown.resources ? uparrow : arrowdown}
              alt="toggle"
            />
          </div>
          <div className="footer_dropdown" ref={contentRefs.resources}>
            <Link onClick={() => handleToggle("resources")} to="/coming-soon">
              <h3>Integrations</h3>
            </Link>
            <Link onClick={() => handleToggle("resources")} to="/coming-soon">
              <h3>Onboarding APIs</h3>
            </Link>
            <Link onClick={() => handleToggle("resources")} to="/coming-soon">
              <h3>Developer Docs</h3>
            </Link>
            <Link onClick={() => handleToggle("resources")} to="/coming-soon">
              <h3>API Reference</h3>
            </Link>
          </div>
          <div className="footer_devider"></div>

          <div
            className="footer_menu_item"
            onClick={() => handleToggle("company")}
          >
            <h3>Company</h3>
            <img
              src={openDropdown.company ? uparrow : arrowdown}
              alt="toggle"
            />
          </div>
          <div className="footer_dropdown" ref={contentRefs.company}>
            <Link onClick={() => handleToggle("company")} to="/about-us">
              <h3>About Us</h3>
            </Link>
            <Link onClick={() => handleToggle("company")} to="/contact-us">
              <h3>Contact Us</h3>
            </Link>
            <Link onClick={() => handleToggle("company")} to="/what-we-do">
              <h3>What we do</h3>
            </Link>
            <a
              href="https://activitybeds.freshdesk.com/support/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>FAQ & Support Center</h3>
            </a>
          </div>
          <div className="footer_devider"></div>

          <div className="footer_social_main">
            <div className="footer_social_text">
              <h2>Follow us</h2>
            </div>
            <div className="footer_social_icons">
              <a
                href="https://www.instagram.com/activitybeds/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={insta} alt="Instagram" />
              </a>
              <a
                href="https://www.linkedin.com/company/activitybeds/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="LinkedIn" />
              </a>
              <a
                href="https://twitter.com/activitybeds"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Twitter" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC3dGUTfjlkze7K41bATa3HA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="YouTube" />
              </a>
              <a
                href="https://www.facebook.com/ActivityBeds-103917885505349"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Facebook" />
              </a>
            </div>
          </div>
        </div>
        <div className="mobile_footer_inner_bottom">
          <div className="footer_devider"></div>
          <h3>
            Copyright © 2024 Activitybeds Technologies{" "}
            {isIndia ? "PVT LTD" : "LLC"}. <br />
            All rights reserved
          </h3>
          <div className="pnt">
            <Link to="/privacy-policy">
              <h2>Privacy Policy</h2>
            </Link>
            <Link to="/terms-and-conditions">
              <h2>Terms & Conditions</h2>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;
