import React from "react";
import "./Pricing.css";
import { Link } from "react-router-dom";
import price from "./pricingtop.png";
import sign from "./sign.svg";
import colorsign from "./colorsign.svg";

const Pricing = () => {
  return (
    <>
      <div className="desktop_div">
        <div className="pricing_main">
          <div className="pricing_main_left">
            <h3>Pricing</h3>
            <h2>
              Transparent Pricing<br></br> for Exceptional Value
            </h2>
            <p>
              Explore our straightforward pricing plans designed to offer
              maximum value and flexibility, <br></br>ensuring you get the most
              out of our innovative travel business
            </p>
          </div>
          <div className="pricing_main_right">
            <img src={price} alt="" />
          </div>
        </div>
        <div className="pricing_second">
          <div className="pricing_second_inner">
            <h3>Pricing</h3>
            <h2>Open Pricing Plans</h2>
            <p>
              Choose from our flexible pricing options designed to provide
              maximum value and support for your travel business needs
            </p>
          </div>
        </div>
        <div className="pricing_plans_main">
          <div className="pricing_plans_top">
            <p className="pricing_plans_top_p">Basic</p>
            <h2 className="pricing_plans_top_h2">
              $5<span className="pricing_plans_top_span">/month</span>
            </h2>
            <div className="pricing_plans_top_inner">
              <div className="pricing_plans_top_inner_main">
                <div className="pricing_plans_top_inner_main_head">
                  <h2>Product:</h2>
                </div>
                <div className="pricing_plans_top_inner_main_content">
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>Marketplace access </p>
                    </div>
                  </div>
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>
                        Payment Solutions & <br></br>Reports
                      </p>
                    </div>
                  </div>
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>
                        My Account Access <br></br>- My Bookings <br></br>- My
                        Ledger <br></br>- My Profile <br></br>- My Settings
                      </p>
                    </div>
                  </div>
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>
                        Channel Manager<br></br>Access
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing_plans_top_inner_main">
                <div className="pricing_plans_top_inner_main_head">
                  <h2>Login&Dashboard:</h2>
                </div>
                <div className="pricing_plans_top_inner_main_content">
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>1 Agent Only</p>
                    </div>
                  </div>
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>
                        Out-of-the-box<br></br> analytics and<br></br> reporting
                      </p>
                    </div>
                  </div>
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>
                        Performance<br></br> Dashboard
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing_plans_top_inner_main">
                <div className="pricing_plans_top_inner_main_head">
                  <h2>Booking:</h2>
                </div>
                <div className="pricing_plans_top_inner_main_content">
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>
                        Seemless Booking Via<br></br> Shopping Cart
                      </p>
                    </div>
                  </div>
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>
                        Confirm the Booking<br></br> without paying <br></br>
                        anything
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing_plans_top_inner_main">
                <div className="pricing_plans_top_inner_main_head">
                  <h2>Payments:</h2>
                </div>
                <div className="pricing_plans_top_inner_main_content">
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>Pay Via Wallet</p>
                    </div>
                  </div>
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>
                        Pay Instant Via credit<br></br> Card
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing_plans_top_inner_main">
                <div className="pricing_plans_top_inner_main_head">
                  <h2>Support:</h2>
                </div>
                <div className="pricing_plans_top_inner_main_content">
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>
                        Chat With Us via<br></br> WhatsApp ChatBot
                      </p>
                    </div>
                  </div>
                  <div className="pricing_plans_top_inner_main_content_inner">
                    <div className="pricing_plans_top_inner_main_content_inner_sign">
                      <img src={sign} alt="" />
                    </div>
                    <div className="pricing_plans_top_inner_main_content_inner_text">
                      <p>Knowladge Base</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pricing_plans_bottom">
            <p className="pricing_plans_bottom_p">GROWTH</p>
            <h2 className="pricing_plans_bottom_head">
              $15<span className="pricing_plans_bottom_span">/month</span>
            </h2>
            <h3 className="pricing_plans_bottom_h3">All in Free +</h3>
            <div className="pricing_plans_bottom_inner">
              <div className="pricing_plans_bottom_inner_main">
                <div className="pricing_plans_bottom_inner_main_head">
                  <h2>Bookings:</h2>
                </div>
                <div className="pricing_plans_bottom_inner_main_content">
                  <div className="pricing_plans_bottom_inner_main_content_inner">
                    <div className="pricing_plans_bottom_inner_main_content_inner_sign">
                      <img src={colorsign} alt="" />
                    </div>
                    <div className="pricing_plans_bottom_inner_main_content_inner_text">
                      <p>
                        Multiple Agents can Login<br></br> Simaltaniously
                      </p>
                    </div>
                  </div>
                  <div className="pricing_plans_bottom_inner_main_content_inner">
                    <div className="pricing_plans_bottom_inner_main_content_inner_sign">
                      <img src={colorsign} alt="" />
                    </div>
                    <div className="pricing_plans_bottom_inner_main_content_inner_text">
                      <p>
                        Products Access | Create My <br></br>Pacakage
                      </p>
                    </div>
                  </div>
                  <div className="pricing_plans_bottom_inner_main_content_inner">
                    <div className="pricing_plans_bottom_inner_main_content_inner_sign">
                      <img src={colorsign} alt="" />
                    </div>
                    <div className="pricing_plans_bottom_inner_main_content_inner_text">
                      <p>Discounted prices by 3%</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing_plans_bottom_inner_main">
                <div className="pricing_plans_bottom_inner_main_head">
                  <h2>Support:</h2>
                </div>
                <div className="pricing_plans_bottom_inner_main_content">
                  <div className="pricing_plans_bottom_inner_main_content_inner">
                    <div className="pricing_plans_bottom_inner_main_content_inner_sign">
                      <img src={colorsign} alt="" />
                    </div>
                    <div className="pricing_plans_bottom_inner_main_content_inner_text">
                      <p>
                        Separate customer Support<br></br> App which contains
                        <br></br>- Updates Via WhatsApp for<br></br> driver
                        details
                      </p>
                    </div>
                  </div>
                  <div className="pricing_plans_bottom_inner_main_content_inner">
                    <div className="pricing_plans_bottom_inner_main_content_inner_sign">
                      <img src={colorsign} alt="" />
                    </div>
                    <div className="pricing_plans_bottom_inner_main_content_inner_text">
                      <p>
                        WhatsApp Group creation with<br></br> 24/7 support
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing_plans_bottom_inner_main">
                <div className="pricing_plans_bottom_inner_main_head">
                  <h2>API:</h2>
                </div>
                <div className="pricing_plans_bottom_inner_main_content">
                  <div className="pricing_plans_bottom_inner_main_content_inner">
                    <div className="pricing_plans_bottom_inner_main_content_inner_sign">
                      <img src={colorsign} alt="" />
                    </div>
                    <div className="pricing_plans_bottom_inner_main_content_inner_text">
                      <p>
                        API Acess Via Channel<br></br>Manager
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing_plans_bottom_inner_main">
                <div className="pricing_plans_bottom_inner_main_head">
                  <h2>Cashback & Discounts:</h2>
                </div>
                <div className="pricing_plans_bottom_inner_main_content">
                  <div className="pricing_plans_bottom_inner_main_content_inner">
                    <div className="pricing_plans_bottom_inner_main_content_inner_sign">
                      <img src={colorsign} alt="" />
                    </div>
                    <div className="pricing_plans_bottom_inner_main_content_inner_text">
                      <p>
                        Cashback Schemes upto USD <br></br>1000/Month
                      </p>
                    </div>
                  </div>
                  <div className="pricing_plans_bottom_inner_main_content_inner">
                    <div className="pricing_plans_bottom_inner_main_content_inner_sign">
                      <img src={colorsign} alt="" />
                    </div>
                    <div className="pricing_plans_bottom_inner_main_content_inner_text">
                      <p>Annual Rewards</p>
                    </div>
                  </div>
                  <div className="pricing_plans_bottom_inner_main_content_inner">
                    <div className="pricing_plans_bottom_inner_main_content_inner_sign">
                      <img src={colorsign} alt="" />
                    </div>
                    <div className="pricing_plans_bottom_inner_main_content_inner_text">
                      <p>Discount on Slab Performance </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile_div">
        <div className="pricing_mobile_main">
          <div className="pricing_mobile_hero">
            <div className="pricing_mobile_hero_title">
              <h3>Pricing</h3>
              <div className="pricing_mobile_hero_title_text_box">
                <h2>Transparent Pricing for Exceptional Value</h2>
                <p>
                  Explore our straightforward pricing plans designed to offer
                  maximum value and flexibility, ensuring you get the most out
                  of our innovative travel business
                </p>
              </div>
            </div>
            <div className="pricing_mobile_hero_image">
              <img src={price} alt="" />
            </div>
          </div>
          <div className="pricing_mobile_howitwork">
            <div className="pricing_mobile_howitwork_services">
              <div className="pricing_mobile_howitwork_services_solutions">
                <h3>Pricing</h3>
                <div className="pricing_mobile_howitwork_services_solutions_text_box">
                  <h2>Open Pricing Plans</h2>
                  <p>
                    Choose from our flexible pricing options designed to provide
                    maximum value and support for your travel business needs
                  </p>
                </div>
              </div>
            </div>
            <div className="pricing_mobile_howitwork_planes">
              <div className="pricing_mobile_howitwork_planes_top">
                <h3 className="pricing_mobile_howitwork_planes_top_h3">
                  Basic
                </h3>
                <h2 className="pricing_mobile_howitwork_planes_top_h2">
                  $5<span>/month</span>
                </h2>
                <div className="pricing_mobile_howitwork_planes_top_line"></div>
                <div className="pricing_mobile_howitwork_planes_top_keys">
                  <div className="pricing_mobile_howitwork_planes_top_keys_inner">
                    <h2>Product:</h2>
                    <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks">
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Marketplace access</h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Payment Solutions & Reports</h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>
                          My Account Access <br></br>- My Bookings <br></br>- My
                          Ledger <br></br>- My Profile <br></br>- My Settings
                        </h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Channel Manager Access</h2>
                      </div>
                    </div>
                  </div>
                  <div className="pricing_mobile_howitwork_planes_top_keys_inner">
                    <h2>Login&Dashboard:</h2>
                    <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks">
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>1 Agent Only</h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Out-of-the-box analytics and reporting</h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Performance Dashboard</h2>
                      </div>
                    </div>
                  </div>
                  <div className="pricing_mobile_howitwork_planes_top_keys_inner">
                    <h2>Booking:</h2>
                    <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks">
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Seemless Booking Via Shopping Cart</h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Confirm the Booking without paying anything</h2>
                      </div>
                    </div>
                  </div>
                  <div className="pricing_mobile_howitwork_planes_top_keys_inner">
                    <h2>Payments:</h2>
                    <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks">
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Pay Via Wallet</h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Pay Instant Via credit Card</h2>
                      </div>
                    </div>
                  </div>
                  <div className="pricing_mobile_howitwork_planes_top_keys_inner">
                    <h2>Support:</h2>
                    <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks">
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Chat With Us via WhatsApp ChatBot</h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_top_keys_inner_checks_frame">
                        <img src={sign} alt="" />
                        <h2>Knowladge Base</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pricing_mobile_howitwork_planes_bottom">
                <h3 className="pricing_mobile_howitwork_planes_bottom_h3">
                  Basic
                </h3>
                <h2 className="pricing_mobile_howitwork_planes_bottom_h2">
                  $15<span>/month</span>
                </h2>
                <h3 className="pricing_mobile_howitwork_planes_bottom_h3_second">
                  All in Free +
                </h3>
                <div className="pricing_mobile_howitwork_planes_bottom_line"></div>
                <div className="pricing_mobile_howitwork_planes_bottom_keys">
                  <div className="pricing_mobile_howitwork_planes_bottom_keys_inner">
                    <h2>Bookings:</h2>
                    <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks">
                      <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame">
                        <img src={colorsign} alt="" />
                        <h2>Multiple Agents can Login SImaltaniously</h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame">
                        <img src={colorsign} alt="" />
                        <h2>Products Access</h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame">
                        <img src={colorsign} alt="" />
                        <h2>Discounted prices by 3%</h2>
                      </div>
                    </div>
                  </div>
                  <div className="pricing_mobile_howitwork_planes_bottom_keys_inner">
                    <h2>Support:</h2>
                    <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks">
                      <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame">
                        <img src={colorsign} alt="" />
                        <h2>
                          Separate customer Support App which contains <br></br>
                          - Updates Via WhatsApp for driver details
                        </h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame">
                        <img src={colorsign} alt="" />
                        <h2>WhatsApp Group creation with 24/7 support</h2>
                      </div>
                    </div>
                  </div>
                  <div className="pricing_mobile_howitwork_planes_bottom_keys_inner">
                    <h2>API:</h2>
                    <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks">
                      <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame">
                        <img src={colorsign} alt="" />
                        <h2>
                        API Access via Channel Manager
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="pricing_mobile_howitwork_planes_bottom_keys_inner">
                    <h2>Cashback & Discounts:</h2>
                    <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks">
                      <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame">
                        <img src={colorsign} alt="" />
                        <h2>
                        Cashback Schemes upto USD 1000/Month
                        </h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame">
                        <img src={colorsign} alt="" />
                        <h2>
                        Annual Rewards
                        </h2>
                      </div>
                      <div className="pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame">
                        <img src={colorsign} alt="" />
                        <h2>
                        Discount on Slab Performance
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="agentmarketplace_mobile_cta">
          <h2>
          Ease into your next adventure: Hit the road tech style with us!
          </h2>
          <Link to="/contact-us">
              <button>Contact Us</button></Link>
        </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
