import React from "react";
import "../PTW.css";
const WhatweDo = () => {
  return (
    <>
      <div className="ptw_top_section">
        <h2>What do we do?</h2>
        <p>
          We provide innovative technology solutions that connect travel
          suppliers with agents worldwide,<br></br> streamlining operations and
          enhancing business growth
        </p>
      </div>
      <div className="ptw_content_outer">
        <div className="ptw_content_inner">
          <h2>Introduction</h2>
          <div className="ptw_content_texts">
            <p>
              When you book an Experience, Transfers & Accommodation,
              ActivityBeds Technologies, LLC (“Activitybeds”) provides the
              Platform, but not the Product itself. Activitybeds is a company
              incorporated under the laws of Delaware, USA, with its place of
              business located at 2803 Philadelphia Pike B # 4271, Claymont, DE
              19703, USA
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>What do we do?</h2>
          <div className="ptw_content_texts">
            <p>
              We provide a place for you to discover and book Experiences,
              Transfers & Accommodation
            </p>
            <p>
              When you make a booking on our Platform, you enter into a contract
              with the Supplier—i.e. the person or entity selling the Experience
              to you, who is responsible for providing the Experience.
            </p>
            <p>
              Before being listed, Suppliers enter into a contract with
              Activitybeds to allow us to promote and make those Experiences
              available for booking on Web, Mobile & App and through third party
              distribution channels. Experiences must meet our Acceptance Criteria and Quality Standards to be listed, and stay listed, on the Platform.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>How we make money?</h2>
          <div className="ptw_content_texts">
            <p>
              We don’t sell any Experiences, Transfers & Accommodation, and we
              don’t own any of the businesses you see listed on our site. When
              you make a Booking, we charge the commission over the price of the
              supplier after you had the Experience.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Prices and payments</h2>
          <div className="ptw_content_texts">
            <p>
              When you make a Booking, you will pay the total price of the
              Booking, inclusive of any taxes and fees. Suppliers are required
              to provide clear information in their listing about any additional
              fees that are payable for optional extras, or about mandatory fees
              that may be payable to a third party upon arrival at, or during,
              the Experience.
            </p>
            <p>
              ActivityBeds Technologies, LLC will process your payment for the
              booking. For more information, refer to Terms of Use.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>If things don't go to plan...</h2>
          <div className="ptw_content_texts">
            <p>
              If you book, Activitybeds will provide customer support in the
              event of a dispute and will work with the Supplier to help the
              parties reach a resolution. However, Activitybeds does not offer
              any guarantees or warranties in relation to any Booking or
              Supplier.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatweDo;
