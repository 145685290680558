import React from 'react';
import './Components/Css/Main.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './Components/Pages/Home/Homepage';
import Navebar from './Components/Navebar/Navebar';
import Footor from './Components/Footor/Footor';
import Agentmarketplace from './Components/Pages/AgentMarektplace/Agentmarketplace';
import ChannelManager from './Components/Pages/ChannelManager/ChannelManager';
import AiTripPlanner from './Components/Pages/AiTripPlanner/AiTripPlanner';
import PaymentSolutions from './Components/Pages/PaymentSolutions/PaymentSolutions';
import Pricing from './Components/Pages/Pricing/Pricing';
import Aboutus from './Components/Pages/AboutUs/Aboutus';
import Contactus from './Components/Pages/Contact Us/Contactus';
import PrivacyPolicy from './Components/Pages/PTW/PrivacyPolicy/Privacypolicy';
import TermsandConditions from './Components/Pages/PTW/Terms and Conditions/TermsandConditions';
import WhatweDo from './Components/Pages/PTW/WhatweDo/WhatweDo';
import Comingsoon from './Components/Pages/ComingSoon/Comingsoon';
import MobileNavebar from './Components/Navebar/MobileNavebar';
import MobileFooter from './Components/Footor/MobileFooter';


function App() {
  return (
    <Router>
      <Navebar/>
      <MobileNavebar/>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/agent-marketplace" element={<Agentmarketplace/>} />
        <Route path="/channel-manager" element={<ChannelManager/>} />
        <Route path="/aitripplanner" element={<AiTripPlanner/>}/>
        <Route path="/payment-solutions" element={<PaymentSolutions/>}/>
        <Route path="/pricing" element={<Pricing/>}/>
        <Route path='/about-us' element={<Aboutus/>}/>
        <Route path='/contact-us' element={<Contactus/>}/>
        <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
        <Route path='/terms-and-conditions' element={<TermsandConditions/>}/>
        <Route path='/what-we-do' element={<WhatweDo/>}/>
        <Route path='/coming-soon' element={<Comingsoon/>}/>
      </Routes>
      <Footor/>
      <MobileFooter/>
    </Router>
  );
}

export default App;
