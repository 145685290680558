import React from "react";
import { Link } from "react-router-dom";
import "./AiTripPlanner.css";
import illu from "./Illustrations.png";
import oneimg from "./threeimgone.png";
import twoimg from "./threeimagetwo.png";
import threeimg from "./threeimagethree.png";
import ada from "./ada.svg";
import solana from "./solana.svg";
import data from "./data.svg";

const AiTripPlanner = () => {
  return (
    <>
      <div className="desktop_div">
        <div className="aitrip_main">
          <div className="aitrip_main_top">
            <h3>AI Trip Planner</h3>
            <h2>
              Adventure Awaits: <br></br>Let our AI Trip Wizard be your guide
            </h2>
            <p>
              Say goodbye to travel stress and hello to unforgettable
              adventures! Our AI Trip Wizard is here to craft your perfect
              journey with a <br></br>sprinkle of magic and a dash of
              innovation. Whether you're craving sandy beaches, bustling cities,
              or hidden gems off the beaten <br></br>path, let our wizard work
              its magic and create a personalized itinerary just for you. Get
              ready to embark on the trip of a lifetime!
            </p>
          </div>
          <div className="aitrip_main_bottom">
            <img src={illu} alt="" />
          </div>
        </div>
        <div className="aitrip_feature">
          <div className="aitrip_feature_inner">
            <h3 className="mainh3">Best Features</h3>
            <h2 className="mainh2">
              Tap into our Lit Tech-Revved travel ventures
            </h2>
            <p className="mainp">
              Learn how our AI Trip Planner harnesses cutting-edge technology to
              effortlessly craft personalized travel itineraries <br></br>{" "}
              tailored to your preferences, interests, and budget
            </p>
          </div>
        </div>
        <div className="aitrip_threesec">
          <div className="aitrip_threesec_inner">
            <div className="aitrip_threesec_inner_text aitriop_textleft">
              <h2 className="aitrip_threesec_inner_textone attit_one_one">
                Add your margins and share
              </h2>
              <h2 className="aitrip_threesec_inner_texttwo attit_one_one">
                directly with the customer
              </h2>
            </div>
            <div className="aitrip_threesec_inner_img aitrip_imgright">
              <img src={oneimg} alt="" />
            </div>
          </div>
          <div className="aitrip_threesec_inner">
            <div className="aitrip_threesec_inner_img aitrip_imgleft">
              <img src={twoimg} alt="" />
            </div>
            <div className="aitrip_threesec_inner_text aitriop_textright">
              <h2 className="aitrip_threesec_inner_textone attit_one_two">
                High quality
              </h2>
              <h2 className="aitrip_threesec_inner_texttwo attit_one_two">
                downloadable files
              </h2>
            </div>
          </div>
          <div className="aitrip_threesec_inner">
            <div className="aitrip_threesec_inner_text aitriop_textleft">
              <h2 className="aitrip_threesec_inner_textone attit_one_three">
                Automated itinerary
              </h2>
              <h2 className="aitrip_threesec_inner_texttwo attit_one_three">
                creation & easy to use
              </h2>
            </div>
            <div className="aitrip_threesec_inner_img aitrip_imgright">
              <img className="aitripimgthree" src={threeimg} alt="" />
            </div>
          </div>
        </div>
        <div className="aitrip_howit_works">
          <div className="aitrip_howit_works_inner">
            <h3>How it works</h3>
            <h2>Behind the scenes: Unveiling our AI Trip Planner's magic</h2>
            <p>
              Peek behind the curtain and uncover the inner workings of our AI
              Trip Planner, seamlessly crafting personalized itineraries based
              on your preferences with technological finesse
            </p>
          </div>
        </div>
        <div className="aitrip_lastthree_sec">
          <div className="aitrip_lastthree_sec_inner">
            <div className="aitrip_lastthree_sec_inner_box aitrip_lastthree_sec_inner_box_one">
              <div className="aitrip_lastthree_sec_inner_box_inner">
                <img className="adaimg" src={ada} alt="" />
                <p>
                  Input your travel preferences and <br></br> destination to the
                  AI Trip Planner
                </p>
              </div>
            </div>
            <div className="aitrip_lastthree_sec_inner_box aitrip_lastthree_sec_inner_box_two">
              <div className="aitrip_lastthree_sec_inner_box_inner">
                <img className="solanaimg" src={solana} alt="" />
                <p>
                  Review your itinerary as needed,ensuring a
                  seamless travel planning experience
                </p>
              </div>
            </div>
            <div className="aitrip_lastthree_sec_inner_box aitrip_lastthree_sec_inner_box_three">
              <div className="aitrip_lastthree_sec_inner_box_inner">
                <img className="dataimg" src={data} alt="" />
                <p>
                  Add your margin as per your liking, download the file, and
                  share directly with your customer
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="aitrip_ease_into">
          <div className="aitrip_ease_into_inner">
            <h3>
              Ease into your next adventure:<br></br> Hit the road tech style
              with us!
            </h3>

            <Link to="/contact-us">
              <button>Contact Us</button></Link>
          </div>
        </div>
      </div>
      <div className="mobile_div">
        <div className="aitripplaner_mobile_hero">
          <div className="aitripplaner_mobile_hero_inner">
            <div className="aitripplaner_mobile_hero_inner_solution">
              <h3>AI Trip Planner</h3>
              <div className="aitripplaner_mobile_hero_inner_text_box">
                <h2>Adventure Awaits:Let our AI Trip Wizard be your guide</h2>
                <p>
                  Say goodbye to travel stress and hello to unforgettable
                  adventures! Our AI Trip Wizard is here to craft your perfect
                  journey with a sprinkle of magic and a dash of innovation.
                  Whether you're craving sandy beaches, bustling cities, or
                  hidden gems off the beaten path, let our wizard work its magic
                  and create a personalized itinerary just for you. Get ready to
                  embark on the trip of a lifetime!
                </p>
              </div>
            </div>
          </div>
          <div className="aitripplaner_mobile_hero_img">
            <img src={illu} alt="" />
          </div>
        </div>
        <div className="aitripplaner_mobile_best_f">
          <div className="aitripplaner_mobile_best_f_services">
            <h3>Best Features</h3>
            <div className="aitripplaner_mobile_best_f_services_text_box">
              <h2>Ta into our Lit Tech-Revved travel ventures</h2>
              <p>
                Learn how our AI Trip Planner harnesses cutting-edge technology
                to effortlessly craft personalized travel itineraries tailored
                to your preferences, interests, and budget
              </p>
            </div>
          </div>
          <div className="aitripplaner_mobile_best_f_features">
            <div className="aitripplaner_mobile_best_f_features_inner">
              <div className="aitripplaner_mobile_best_f_features_inner_text_box">
                <div className="aitripplaner_mobile_best_f_features_inner_text_box_top ambffitbtone">
                  <h2>Add your margins and share</h2>
                </div>
              </div>
              <div className="aitripplaner_mobile_best_f_features_inner_text_box">
                <div className="aitripplaner_mobile_best_f_features_inner_text_box_bottom ambffitbtone">
                  <h2>directly with the customer</h2>
                </div>
              </div>
              <div className="aitripplaner_mobile_best_f_features_inner_image ambffii">
                <img src={oneimg} alt="" />
              </div>
            </div>
            <div className="aitripplaner_mobile_best_f_features_inner">
              <div className="aitripplaner_mobile_best_f_features_inner_text_box">
                <div className="aitripplaner_mobile_best_f_features_inner_text_box_top ambffitbttwo">
                  <h2>High quality</h2>
                </div>
              </div>
              <div className="aitripplaner_mobile_best_f_features_inner_text_box">
                <div className="aitripplaner_mobile_best_f_features_inner_text_box_bottom ambffitbbtwo">
                  <h2>downloadable files</h2>
                </div>
              </div>
              <div className="aitripplaner_mobile_best_f_features_inner_image">
                <img src={twoimg} alt="" />
              </div>
            </div>
            <div className="aitripplaner_mobile_best_f_features_inner">
            <div className="aitripplaner_mobile_best_f_features_inner_text_box">
                <div className="aitripplaner_mobile_best_f_features_inner_text_box_top ambffitbtthree">
                  <h2>Automated itinerary</h2>
                </div>
              </div>
              <div className="aitripplaner_mobile_best_f_features_inner_text_box">
                <div className="aitripplaner_mobile_best_f_features_inner_text_box_bottom ambffitbbthree">
                  <h2>creation & easy to use</h2>
                </div>
              </div>
              <div className="aitripplaner_mobile_best_f_features_inner_image">
                <img src={threeimg} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="aitrip_mobile_hiw">
          <div className="aitrip_mobile_hiw_services">
            <div className="aitrip_mobile_hiw_services_solution">
              <h3>How it Works</h3>
              <div className="aitrip_mobile_hiw_services_text_box">
                <h2>
                  Behind the scenes: Unveiling our AI Trip Planner’s magic
                </h2>
                <p>
                  Peek behind the curtain and uncover the inner workings of our
                  AI Trip Planner, seamlessly crafting personalized itineraries
                  based on your preferences with technological finesse
                </p>
              </div>
            </div>
          </div>
          <div className="aitrip_mobile_hiw_features">
            <div className="aitrip_mobile_hiw_features_main amhfmone">
              <img src={ada} alt="" />
              <h2>
                Input your travel preferences and destination to the AI Trip
                Planner
              </h2>
            </div>
            <div className="aitrip_mobile_hiw_features_main amhfmtwo">
              <img src={solana} alt="" />
              <h2>
                Input your travel preferences and destination to the AI Trip
                Planner
              </h2>
            </div>
            <div className="aitrip_mobile_hiw_features_main amhfmthree">
              <img src={data} alt="" />
              <h2>
                Input your travel preferences and destination to the AI Trip
                Planner
              </h2>
            </div>
          </div>
        </div>
        <div className="agentmarketplace_mobile_cta">
          <h2>
            Ease into your next adventure: Hit the road tech style with us!
          </h2>
          <Link to="/contact-us">
              <button>Contact Us</button></Link>
        </div>
      </div>
    </>
  );
};

export default AiTripPlanner;
