import React from 'react'
import './Comingsoon.css'
import comingsoon from './Coming Soon.png'

const Comingsoon = () => {
  return (
    <div className='Comingsoon_main'>
        <img src={comingsoon} alt="" />
    </div>
  )
}

export default Comingsoon