import React from "react";
import { Link } from "react-router-dom";
import "./PaymentSolutions.css";
import payment from "./Online-Transaction.png";
import tazapay from "./tazapay.svg";
import presspay from "./presspay.svg";
import razorpay from "./razorpay.svg";
import stripe from "./stripe.svg";
import wise from "./wise.svg";
import money from "./money.svg";
import globe from "./globe.svg";
import realtime from "./realtime.svg";
import best from "./best.svg";
import simplyfied from "./simplyfied.svg";
import instarem from './instarem.svg'
const PaymentSolutions = () => {
  return (
    <>
      <div className="desktop_div">
        <div className="PaymentSolutions_main">
          <div className="PaymentSolutions_payment">
            <div className="PaymentSolutions_payment_left">
              <h3>Payment Solutions</h3>
              <h2>
                Streamline Transactions with our <br></br>Travel Finance Suite
              </h2>
              <p>
                Empower your travel business with our comprehensive finance
                suite, ensuring smooth <br></br>and secure transactions from
                booking to checkout
              </p>
            </div>
            <div className="PaymentSolutions_payment_right">
              <img src={payment} alt="" />
            </div>
          </div>
          <div className="PaymentSolutions_logos">
            <div className="PaymentSolutions_logos_inner">
              <img src={tazapay} alt="" />
              <img className="instarem_logo" src={instarem} alt="" />
              <img src={razorpay} alt="" />
            </div>
            <div className="PaymentSolutions_logos_inner">
              <img src={wise} alt="" />
              <img src={stripe} alt="" />
            </div>
          </div>
          
          <div className="PaymentSolutions_fivebox_main">
            <div className="PaymentSolutions_fivebox_main_top">
              <div className="PaymentSolutions_fivebox_main_top_one">
                <img src={money} alt="" />
                <p>
                  Native currency nirvana: Experience<br></br> frictionless
                  local currency payments
                </p>
              </div>
              <div className="PaymentSolutions_fivebox_main_top_two">
                <img src={globe} alt="" />
                <p>
                  Global currency flexibility: Access 135+<br></br> currencies
                  across 90+ nations
                </p>
              </div>
              <div className="PaymentSolutions_fivebox_main_top_three">
                <img src={realtime} alt="" />
                <p>
                  Real-time transaction velocity: Instantaneous <br></br>payment
                  processing capabilities
                </p>
              </div>
            </div>
            <div className="PaymentSolutions_fivebox_main_bottom">
              <div className="PaymentSolutions_fivebox_main_bottom_one">
                <img src={best} alt="" />
                <p>
                  Best mid-market<br></br> exchange rates
                </p>
              </div>
              <div className="PaymentSolutions_fivebox_main_bottom_two">
                <img src={simplyfied} alt="" />
                <p>
                  Simplified compliance requirements :<br></br> Efortless
                  regulatory navigations
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile_div">
        <div className="payment_solutions_mobile_main">
          <div className="payment_solutions_mobile_hero">
            <div className="payment_solutions_mobile_hero_title">
              <h3>Payment Solutions</h3>
              <div className="payment_solutions_mobile_hero_title_text_box">
                <h2>Streamline Transactions with our Travel Finance Suite</h2>
                <p>
                  Empower your travel business with our comprehensive finance
                  suite, ensuring smooth and secure transactions from booking to
                  checkout
                </p>
              </div>
            </div>
            <div className="payment_solutions_mobile_hero_img">
              <img src={payment} alt="" />
            </div>
          </div>
          <div className="payment_solutions_mobile_logos">
            <img src={tazapay} alt="" />
            <img src={instarem} alt="" />
            <img src={razorpay} alt="" />
            <img src={wise} alt="" />
            <img src={stripe} alt="" />
          </div>
          <div className="payment_solutions_mobile_howitworks">
            <div className="payment_solutions_mobile_howitworks_services">
              <div className="payment_solutions_mobile_howitworks_services_solutions">
                <h3>How it Works</h3>
                <div className="payment_solutions_mobile_howitworks_services_solutions_text_box">
                  <h2>
                    Behind the scenes: Unveiling our AI Trip Planner’s magic
                  </h2>
                  <p>
                    Peek behind the curtain and uncover the inner workings of
                    our AI Trip Planner, seamlessly crafting personalized
                    itineraries based on your preferences with technological
                    finesse
                  </p>
                </div>
              </div>
            </div>
            <div className="payment_solutions_mobile_howitworks_features">
              <div className="payment_solutions_mobile_howitworks_features_inner_divs">
                <img className="psmhfidimgone" src={money} alt="" />
                <h2>
                  Native currency nirvana: Experience frictionless local
                  currency payments
                </h2>
              </div>
              <div className="payment_solutions_mobile_howitworks_features_inner_divs">
              <img className="psmhfidimgtwo" src={globe} alt="" />
                <h2>
                Global currency flexibility: Access 135+ currencies across 90+ nations
                </h2>
              </div>
              <div className="payment_solutions_mobile_howitworks_features_inner_divs">
              <img className="psmhfidimgthree" src={realtime} alt="" />
                <h2>
                Real-time transaction velocity: Instantaneous payment processing capabilities
                </h2>
              </div>
              <div className="payment_solutions_mobile_howitworks_features_inner_divs">
              <img className="psmhfidimgfour" src={best} alt="" />
                <h2>
                Best mid-market exchange rates
                </h2>
              </div>
              <div className="payment_solutions_mobile_howitworks_features_inner_divs">
              <img className="psmhfidimgfive" src={simplyfied} alt="" />
                <h2>
                Simplified compliance requirements : Efortless regulatory navigations
                </h2>
              </div>
            </div>
          </div>
          <div className="agentmarketplace_mobile_cta">
          <h2>
          Ease into your next adventure: Hit the road tech style with us!
          </h2>
          <Link to="/contact-us">
              <button>Contact Us</button></Link>
        </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSolutions;
