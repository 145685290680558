import React from "react";
import "../PTW.css";

const TermsandConditions = () => {
  return (
    <>
      <div className="ptw_top_section">
        <h2>Terms & Conditions</h2>
        <p>
          Review our Terms & Conditions to understand the guidelines and
          policies governing the use of our services and platform
        </p>
      </div>
      <div className="ptw_content_outer">
        <div className="ptw_content_texts">
          <p>
            We assist Travel Partners with trip planning and research, review
            and schedule travel experiences, and participate in travel
            discussion boards. But since we're not a travel or tour company, we
            don't offer these kinds of experiences ourselves. You are buying a
            tour, ticket, or other service straight from the third-party
            provider when you make a reservation.
          </p>
          <p>
            If you use Activitybeds’ services in any way, these terms of use
            will apply. Please take time to carefully read these terms of use,
            as they include essential information about your legal rights and
            how they may be limited, as well as a section on relevant law and
            dispute jurisdiction. You acknowledge that you have read these terms
            of use and agree to be bound by them by accessing or using
            Activitybeds’ services.
          </p>
          <p>
            Activitybeds reserves the right to change the Terms of Use anytime
            based on the changing conditions & the updated terms of use would be
            reflected on the website.
          </p>
        </div>
        <div className="ptw_content_inner">
          <h2>Introduction</h2>
          <div className="ptw_content_texts">
            <p>
              These phrases of use, collectively with the Privacy Policy
              (together, the "Terms of Use") set out the phrases on which
              Activitybeds Technologies,LLC.(a Delaware company, collectively
              with sure specific associates and subsidiaries: "Activitybeds",
              "we", "us, "our") offers services (the "Services") via
              Activitybeds reserving platform (the "Platform") as can be made to
              be had via our website (www.activitybeds.com, collectively with
              all associated domains, white label and associate sites, cell
              homes and associated applications, together called the "Website")
              and over the telephone. When we refer to "you", we suggest any
              Travel Partner that accesses or makes use of the Services.
              Activitybeds isn't a journey or excursion agency, neither is it a
              provider of tours, sports or experiences. When you're making a
              reserving, you're the using the Platform to settlement.
            </p>
            <p>
              If you want to get in touch with Activitybeds, please go to our
              Activitybeds Contact Us page. You also can write to us at the
              subsequent address: ActivityBeds Technologies, LLC 2803
              Philadelphia Pike B # 4271, Claymont, DE 19703, United States.
            </p>
            <p>
              These Terms of Use govern your courting with us in recognize of
              your use of the Services, consisting of any bookings which you
              make of any excursion, ticket, attraction, interest and/or
              experience (defined in those Terms of Use as an "Experience") this
              is marketed at the website and is made to be had with the aid of
              using third party supplier".
            </p>
            <p>
              By the usage of or getting access to the Services, with the aid of
              using reserving a Product ("Book", "Booking"), and/or developing
              an account at the Website you agree to be sure with the aid of
              using those Terms of Use without modification, and also you
              constitute which you have examine and understood them. In all
              booking arrangements, the Travel Partner making the booking will
              be deemed to have read & understood those Terms of Use.
            </p>
            <p>
              We can additionally replace or in any other case adjust those
              Terms of Use at any time, and also you recognize and agree that
              your endured get right of entry to or use of the Services after
              such alternate indicates your attractiveness of the up to date
              Terms of Use. We will word the date that updates have been closing
              made to the Terms of Use on the pinnacle of this web page, and the
              up to date Terms. It is your duty to go back to this web page
              periodically to study the maximum cutting-edge model of the Terms
              of Use.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Use of the Services</h2>
          <div className="ptw_content_texts">
            <p>
              As a circumstance of your use of the Services, you constitute and
              warrant that (i) all data provided with the aid of using you
              within side the path of your use of the Services is true,
              accurate, modern-day and complete, and (ii) you're registered
              Travel Partner that allows you to use the Website.
            </p>
            <p>
              Your use of the services is allowed with the aid of using
              Activitybeds simplest for business use and/or to make valid
              requests to make a booking of the products offered. You agree now
              no longer to apply this Service to make any speculative, fake or
              fraudulent requests or Bookings.
            </p>
            <p>
              You similarly constitute and warrant that you (a) aren't presently
              suspended and no longer formerly been banned with the aid of using
              Activitybeds from the usage of the Services; (b) aren't performing
              on behalf of a competitor of Activitybeds; (c) have complete
              strength and authority to go into this legally binding settlement
              and in doing so will now no longer violate every other settlement
              to that you are a party.
            </p>
            <p>
              We keep the proper at our sole discretion to disclaim get right of
              entry to the services to all and sundry at any time and for any
              reason, including, however now no longer restrained to, for
              violation of those Terms of Use.
            </p>
            <p>
              In having access to or the usage of the website, you will be
              uncovered to content material this is offensive, indecent,
              inaccurate, objectionable, or in any other case inappropriate.
              Activitybeds does not suggest such content material, and cannot
              vouch for its accuracy. You consequently get right of entry to and
              use the Website at your personal risk.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Activitybeds Agent Accounts</h2>
          <div className="ptw_content_texts">
            <p>
              We create an Activitybeds account in order to use some of the
              features on the website and other Services, including when you
              make a Booking, you are responsible for maintaining the
              confidentiality of your Activitybeds Account password and log-in
              credentials. You are also solely responsible for all activities
              (including Bookings) that occur in connection with your
              Activitybeds Account. You agree to notify us immediately of any
              unauthorized use of your Activitybeds Account.
            </p>
            <p>
              We may terminate or suspend access to your Activitybeds Account or
              your ability to use the Services, in whole or in part, at our sole
              discretion, for any or no reason, and without notice or liability
              of any kind.
            </p>
            <p>
              AS A USER OF THE SERVICES, YOU UNDERSTAND AND AGREE THAT: (1)
              NEITHER ACTIVITYBEDS NOR ITS AFFILIATES WILL HAVE ANY LIABILITY TO
              YOU OR OTHERS FOR ANY UNAUTHORIZED BOOKINGS MADE USING YOUR
              ACTIVITYBEDS ACCOUNT AND/OR ACTIVITYBEDS ACCOUNT CREDENTIALS; AND
              (2) THE UNAUTHORIZED USE OF YOUR ACTIVITYBEDS ACCOUNT AND/OR
              ACTIVITYBEDS ACCOUNT CREDENTIALS COULD CAUSE YOU TO INCUR
              LIABILITY TO BOTH ACTIVITYBEDS AND OTHER TRAVEL PARTNERS.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Website Prohibited Activities</h2>
          <div className="ptw_content_texts">
            <p>
              The content material and facts at the Website (such as, however
              now no longer confined to, messages, data, facts, text, music,
              sound, photos, graphics, video, maps, icons, software (such as
              Software, code or different material, and together defined herein
              as the "Website Content"), in addition to the infrastructure used
              to offer such Website Content, is proprietary to us. You agree now
              no longer to in any other case modify, copy, distribute, transmit,
              display, perform, reproduce, publish, license, create spinoff
              works from, transfer, or promote or re-promote any Website
              Content, or any services or products received from or through the
              Website. Any different use of the Website Content, merchandise
              and/or offerings calls for the previous written permission of
              Activitybeds.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Content</h2>
          <div className="ptw_content_texts">
            <p>
              You and different agents may also make contributions to the
              Website in some of one of a kind ways, which includes filing
              emails, writing reviews, making postings, importing and posting
              pictures or videos, making remarks or suggestions, filing ideas,
              filling out public profiles, score different contributions and
              making different comparable contributions or submissions to the
              Website and/or in any other case to Activitybeds (collectively,
              "User Content"). We may also use User Content in some of one of a
              kind ways, which includes however now no longer constrained to
              showing it at the Website, reformatting it, translating it into
              different languages, modifying it for readability and grammar,
              incorporating it into commercials and different works, growing by-
              product works from it, selling it, dispensing it, and permitting
              others to do the identical in reference to their personal
              websites, cellular properties, applications, and media platforms.
              By filing User Content, you furnish Activitybeds and its
              associates a worldwide, non-exclusive, royalty-free, absolutely
              paid-up, perpetual, transferable, irrevocable and absolutely sub-
              licensable property (a) use, reproduce, modify, adapt, translate,
              distribute, publish, create by- product works from and publicly
              show and carry out such User Content at some point of the sector
              in any media, now recognized or hereafter devised, for any
              purpose; and (b) use the call which you post in reference to such
              User Content.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Software Restriction</h2>
          <div className="ptw_content_texts">
            <p>
              Software from the Website is further subject to United States
              export controls. No software from the Website may be downloaded or
              otherwise exported or re-exported (a) into (or to a national or
              resident of) Cuba, Iraq, Sudan, North Korea, Iran, Syria, or any
              other country to which the U.S. has embargoed goods, or (b) to
              anyone on the U.S. Treasury Department list of Specially
              Designated Nationals or the U.S. Commerce Department's Table of
              Deny Orders. By using the Website, you represent and warrant that
              you are not located in, under the control of, or a national or
              resident of any such country or on any such list.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Products and Booking Reservations</h2>
          <div className="ptw_content_texts">
            <p>
              Activitybeds offers the Platform that enables you to sign a
              contract with a third-party product supplier ("Supplier"). You and
              the relevant Supplier will enter into a direct contract for the
              supply of any Product you book; Activitybeds is not a supplier of
              any kind. Activitybeds serves as the Supplier's disclosed agent
              with regard to each Booking; this means that a Product listing is
              an invitation for you to submit an offer to a Supplier, and that
              we have the discretion to accept or reject such an offer on the
              Supplier's behalf. However, the provision of the Product you Book
              will be subject to the terms and conditions displayed on the
              Website in respect of such Product, any information made available
              to you during the Booking process, and the terms and conditions of
              the Supplier with whom you have a legal contract for the supply of
              the Product. Your use of the Services, including the Platform via
              which you can make such an offer to a user, is governed by these
              Terms of Use. If you have a question about a Product that is not
              answered in the information on the Website and where the answer to
              your question may influence your booking decision, pls contact
              Activitybeds for the same. Once you have made a Booking, you are
              subject to the cancellation policy as stated in the Product
              listing.
            </p>
            <p>
              If you make a Booking, Your interactions with Suppliers are at
              your own risk. Activitybeds will have no liability with respect to
              the acts, omissions, errors, representations, warranties, breaches
              or negligence of any Supplier or for any personal injuries, death,
              property damage, or other damages or expenses resulting from your
              interactions with any Supplier.
            </p>
            <p>
              Activitybeds is not a travel agency and does not provide or own
              any Experiences. Although Activitybeds provides the Website with
              information about Products and facilitates Bookings, such actions
              do not in any way constitute Activitybeds sponsorship or approval
              of such Suppliers, or any affiliation between Activitybeds and any
              Supplier. You agree that Activitybeds is not responsible for the
              accuracy or completeness of information it obtains from Suppliers
              and/or that is displayed on the Website.
            </p>
            <p>
              By making a Booking, you warrant that you are at least 18 years of
              age, that you possess the legal authority to enter into both this
              binding agreement and a binding agreement with the Supplier, to
              use the Services, to purchase the Product, and that all
              information you supply is true and accurate. You further agree
              that you will use the Platform to make only legitimate Bookings
              for you and/or others for whom you are legally authorized to act.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Pricing</h2>
          <div className="ptw_content_texts">
            <p>
              The price of each Product will be quoted on a per-person basis,
              unless otherwise specified.
            </p>
            <p>
              Prices are based on the USD at the time of quoting, converted at
              the prevailing foreign- exchange rate as determined by
              Activitybeds.
            </p>
            <p>
              Price quotations are subject to change without notice, until a
              Booking has been made.
            </p>
            <p>
              Unless the Supplier has otherwise specified, prices do not include
              any local taxes or use-fees, including foreign departure,
              security, port charges, park fees, customs, immigration,
              agricultural, passenger-facility charges or international
              transportation tax.
            </p>
            <p>
              Inclusions are determined by the Supplier. Prices do not include
              tips/gratuities; passport and visa fees; baggage and personal
              insurance; any items of a personal nature; taxes or duties; and
              any beverages or food not specifically stated as included by the
              Supplier.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Currency Conversions</h2>
          <div className="ptw_content_texts">
            <p>
              Activitybeds only takes the reference rates for currency
              conversion. Please note that these are not interbank rates. In
              case of booking The rate charged by the bank for currency
              conversion in different geographies will apply.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Payments</h2>
          <div className="ptw_content_texts">
            <p>
              When you make a Booking, Activitybeds Technologies LLC will
              collect your payment information and will process your payment VIA
              our payment partners. In doing so, Activitybeds Technologies, LLC
              acts as the limited payment collection agent of the Supplier,
              collecting your payment for the applicable Product(s) on behalf of
              such Supplier. Full payment by credit or debit card is required to
              make a Booking, unless otherwise specified. The payee may be
              referenced as Activitybeds on your statement.
            </p>
            <p>
              The value of your Booking may be subject to taxes, duties, foreign
              transaction, currency exchange or other fees. Your bank or payment
              card company may convert the payment into the local currency and
              may charge fees, resulting in differences between the amount
              displayed through the Platform, and the final amount charged to
              you. Activitybeds recommends that you contact your bank or card
              company if you have any questions concerning any applicable
              currency conversion or fees.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Modifications or Cancellations</h2>
          <div className="ptw_content_texts">
            <p>
              Once a Product has been purchased, your Booking cannot be changed
              by you or cancelled with a refund, unless otherwise stated in the
              terms and conditions applicable to such Product. If you request
              that any element of your Booking is changed (including but not
              limited to your pick-up location or time), you acknowledge that it
              is entirely at the applicable Supplier’s discretion as to whether
              to accommodate your request. It is your responsibility to be at
              the meeting place at the time and date communicated on your
              Booking. In the case of certain Products, exact locations and
              times will be communicated to you after your Booking is made by
              the applicable Supplier. It is your responsibility to check such
              messages after your Booking is made, and to be at the meeting
              place at the time and date that are communicated by the Supplier.
              If you are not at such meeting point at the required time, the
              Supplier may offer to accommodate you at an alternative time and
              date. However, the Supplier shall be under no obligation to do so,
              and your failure to be at the communicated meeting point at the
              required time may result in your Booking being classified as a “no
              show”, meaning that you will not be entitled to any refund or to
              being rebooked.
            </p>
            <p>
              In all circumstances, you must check the cancellation policy
              contained in the applicable Product listing at the time of your
              Booking, which is the policy that will apply to and govern the
              terms of your cancellation and any refunds.
            </p>
            <p>
              No refunds are available once an Experience has commenced, or in
              respect of any Product's package, accommodation, meals or any
              other services that have started to be utilized
            </p>
            <p>
              Occasionally a Supplier may make a change to a Product after your
              purchase, including but not limited to the date, price,
              inclusions, coverage, age requirements, and/or any other Product
              features and/or requirements. As a result, Activitybeds (acting on
              the Supplier’s behalf), reserves the right to cancel, change or
              substitute any Product that you have purchased, at any time, for
              any reason. If the change proposed by the Supplier is material
              (for example, a change in dates and/or a significant change to the
              itinerary), and you are dissatisfied with the alternative that is
              offered, you will be entitled to a full refund of the original
              purchase price.
            </p>
            <p>
              We may decide, in our sole discretion, that it is necessary or
              desirable for the protection of our interests, the Supplier's
              interests and/or your interests, to withdraw our Services
              resulting in an override of the Product's cancellation policy and
              the effective cancellation of a Booking. We may also determine, in
              our sole discretion, to arrange refund to you part or all of the
              amounts charged to you. You agree that we and the applicable
              Supplier shall have no liability for such cancellations or
              refunds.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Passports, Visas & Insurance</h2>
          <div className="ptw_content_texts">
            <p>
              When you make a Booking, you are responsible for ensuring that you
              meet all foreign entry requirements and that your travel
              documents, including passports and visas, are in order
            </p>
            <p>
              For applicable passport and visa requirements, you should consult
              the relevant embassy or consulate for information. Such
              requirements may change at any time, and it is your responsibility
              to check for up-to-date information before making a Booking and
              before your departure. Activitybeds accepts no liability in
              connection with any person who is refused entry onto a flight or
              into any country, including countries such person may just be
              passing through en route to their destination
            </p>
            <p>
              Activitybeds recommends that you verify health and visa
              requirements with the appropriate consulate prior to departure.
              Also we strongly recommend that you purchase a comprehensive
              travel insurance policy prior to departure to protect your Product
              purchase
            </p>
            <p>
              Although most travel, including travel to international
              destinations, is completed without incident, travel to certain
              destinations may involve greater risk than others. Activitybeds
              urges customers to investigate and review travel prohibitions,
              warnings, announcements and advisories issued by the United States
              Government and destination country governments prior to booking
              travel to international destinations.
            </p>
            <p>
              ACTIVITYBEDS DOES NOT REPRESENT OR WARRANT THAT TRAVEL TO ANY
              DESTINATION IS ADVISABLE OR WITHOUT RISK, AND ACTIVITYBEDS IS NOT
              LIABLE FOR DAMAGES OR LOSSES THAT MAY RESULT FROM TRAVEL TO SUCH
              DESTINATIONS
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Our Liability</h2>
          <div className="ptw_content_texts">
            <p>
              THE INFORMATION, CONTENT, SOFTWARE, PLATFORM, AND SERVICES
              PROVIDED BY ACTIVITYBEDS MAY INCLUDE INACCURACIES OR ERRORS,
              INCLUDING IN RELATION TO RESERVATION AVAILABILITY AND PRICING
              ERRORS. ACTIVITYBEDS DO NOT GUARANTEE THE ACCURACY OF, AND
              DISCLAIM ALL LIABILITY FOR, ANY ERRORS OR OTHER INACCURACIES
              RELATING TO THE INFORMATION AND DESCRIPTION OF THE PRODUCTS AND
              OTHER SERVICES (INCLUDING, WITHOUT LIMITATION, THE PRICING,
              AVAILABILITY, PHOTOGRAPHS, FEATURES, INCLUSIONS AND EXCLUSIONS,
              GENERAL PRODUCT DESCRIPTIONS, REVIEWS AND RATINGS, ETC.). IN
              ADDITION, ACTIVITYEBDS EXPRESSLY RESERVES THE RIGHT TO CORRECT ANY
              AVAILABILITY AND PRICING ERRORS AND/OR ON BOOKINGS MADE UNDER AN
              INCORRECT PRICE.
            </p>
            <p>
              ACTIVITYBEDS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE
              INFORMATION, CONTENT, SOFTWARE, PLATFORM, PRODUCTS, AND OTHER
              SERVICES FOR ANY PURPOSE, AND THE INCLUSION OR OFFERING OF ANY
              PRODUCTS OR SERVICES DOES NOT CONSTITUTE ANY ENDORSEMENT OR
              RECOMMENDATION OF SUCH PRODUCTS OR SERVICES. ALL SUCH INFORMATION,
              CONTENT, SOFTWARE, PLATFORM, PRODUCTS, AND SERVICES ARE PROVIDED
              "AS IS" WITHOUT WARRANTY OF ANY KIND. ACTIVITYBEDS DISCLAIM ALL
              WARRANTIES AND CONDITIONS THAT THE PLATFORM IS FREE OF VIRUSES OR
              OTHER HARMFUL COMPONENTS. ACTIVITYEBDS HEREBY DISCLAIMS ALL
              WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
              CONTENT, SOFTWARE, PLATFORM, PRODUCTS, AND SERVICES, INCLUDING ALL
              IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, TO THE EXTENT
              PERMITTED BY LAW
            </p>
            <p>
              THE SUPPLIERS ARE INDEPENDENT CONTRACTORS AND NOT AGENTS OR
              EMPLOYEES OF ANY OF THE ACTIVITYBEDS GROUP COMPANIES. ACTIVITYBEDS
              IS NOT LIABLE FOR THE ACTS, ERRORS, OMISSIONS, REPRESENTATIONS,
              WARRANTIES, BREACHES, NEGLIGENCE OR MISCONDUCT OF ANY SUCH
              SUPPLIERS OR FOR ANY PERSONAL INJURY, DEATH, PROPERTY DAMAGE, OR
              OTHER DAMAGES OR EXPENSES RESULTING THEREFROM OR OTHERWISE ARISING
              FROM ANY BOOKING OR USE OF A PRODUCT. ACTIVITYBEDS HAVE NO
              LIABILITY AND WILL MAKE NO REFUND IN THE EVENT OF ANY DELAY,
              CANCELLATION, OVERBOOKING, STRIKE, FORCE MAJEURE OR OTHER CAUSES
              BEYOND THEIR DIRECT CONTROL, AND THEY HAVE NO RESPONSIBILITY FOR
              ANY ADDITIONAL EXPENSE, OMISSIONS, DELAYS, RE-ROUTING OR ACTS OF
              ANY GOVERNMENT OR AUTHORITY.
            </p>
            <p>
              IN NO EVENT SHALL THE ACTIVITYBEDS TECHNOLOGIES,LLC (OR ANY OF
              THEIR OFFICERS, DIRECTORS AND AFFILIATES) BE LIABLE FOR ANY
              DIRECT, INDIRECT, OR USE OF THE SERVICES WHETHER BASED ON A THEORY
              OF NEGLIGENCE, PUNITIVE, INCIDENTAL, SPECIAL, EXEMPLARY OR
              CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION LOSS OF DATA
              OR INFORMATION OR COSTS TO PROCURE SUBSTITUTE GOODS OR SERVICES)
              ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, A PRODUCT OR A
              BOOKING, OR YOUR ACCESS TO, DISPLAY OF CONTRACT, TORT, STRICT
              LIABILITY, OR OTHERWISE, AND EVEN IF ACTIVITYBEDS HAS BEEN ADVISED
              OF THE POSSIBILITY OF SUCH DAMAGES
            </p>
            <p>
              IN CASE IF ACTIVITYBEDS TECHNOLGIES,LLC IS FOUND LIABLE FOR ANY
              LOSS OR DAMAGE THAT ARISES OUT OR IS ANYWAY CONNECTED WITH THE USE
              OF THE SERVICES , BOOKING , USE OF THE PRODUCT , THEN THE
              ACTIVITYBEDS TECHNOLOGIES, LLC LIABILITIES WILL NO EVENT EXCEED ,
              IN THE AGGREGATE THE GREATER OF (1) THE PRICE OF THE PRODUCT
              BOOKED OR (2) ONE HUNDERED UNITED STATES DOLLARS (USD 100)
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Your Liability</h2>
          <div className="ptw_content_texts">
            <p>
              The user/Agent agree to defend and indemnify ActivityBeds and all
              of their respective officers, directors, employees and agents from
              and against any claims, causes of action, demands, suits,
              proceedings, investigations, losses, damages, fines, penalties,
              fees, expenses, costs and any other liabilities of any kind or
              nature including but not limited to reasonable legal and
              accounting fees, arising out of or related to: a. your breach of
              these Terms of Use or the documents referenced herein; b. your
              violation of any law, rule, regulation or guideline; c. your
              violation, infringement or misappropriation of the rights of a
              third party, including without limitation any rights of publicity
              or privacy; d. your use of the Services (including the Website);
              e. your Booking or use of any Product; or f. your negligence or
              willful misconduct.
            </p>
            <p>
              To the extent allowed by applicable law, you agree that you will
              bring any claim or cause of action arising from or relating to
              your access or use of the Services within Six (6) months from the
              date on which such claim or action arose or accrued or such claim
              or cause of action will be irrevocably waived.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Vouchers & e-Tickets</h2>
          <div className="ptw_content_texts">
            <p>
              For security purposes, when redeeming your Ticket you must present
              a valid photo ID and sign the Ticket.
            </p>
            <p>
              In some cases, a Supplier may also require that you present the
              payment card used to purchase your Product at the time of
              redemption. This typically applies to attraction and show tickets.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Jurisdiction and Governing Law</h2>
          <div className="ptw_content_texts">
            <p>
              The Services are provided by a U.S. entity and these Terms of Use
              is governed by the laws of the State of Delaware, USA. You hereby
              consent to the exclusive jurisdiction and venue of courts in
              Claymont, USA and stipulate to the fairness and convenience of
              proceedings in such courts for all disputes arising out of or
              relating to the use of the Services. You agree that all claims you
              may have against ActivityBeds arising from or relating to the
              Services must be heard and resolved in a court of competent
              subject matter jurisdiction located in Claymont, USA.
            </p>
            <p>
              Use of the Services is unauthorized in any jurisdiction that does
              not give effect to all provisions of these terms and conditions,
              including, without limitation, this paragraph. The foregoing shall
              not apply to the extent that applicable law in your country of
              residence requires application of another law and/or jurisdiction
              and this cannot be excluded by contract.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsandConditions;
