import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navebar.css";
import logo from "./activitybed.png";

const Navebar = () => {
  const [showSolutionDropdown, setShowSolutionDropdown] = useState(false);
  const [showResourcesDropdown, setShowResourcesDropdown] = useState(false);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  // Function to toggle Solution dropdown
  const toggleSolutionDropdown = () => {
    setShowSolutionDropdown(!showSolutionDropdown);
  };

  // Function to toggle Resources dropdown
  const toggleResourcesDropdown = () => {
    setShowResourcesDropdown(!showResourcesDropdown);
  };

  // Function to close all dropdowns
  const closeDropdowns = () => {
    setShowSolutionDropdown(false);
    setShowResourcesDropdown(false);
  };

  return (
    <div className="navebar_main desktop-navbar">
      <div className="navlogo">
        <Link to="/" onClick={scrollToTop}>
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="navitems">
        {/* Solution Dropdown */}
        <div
          className="navitem_dropdown"
          onMouseEnter={toggleSolutionDropdown}
          onMouseLeave={closeDropdowns}
        >
          <button className="navitem_buttons">Solution</button>
          {showSolutionDropdown && (
            <div className="dropdown-content">
              <Link to="/agent-marketplace">Agent Marketplace</Link>
              <Link to="/channel-manager">Channel Manager</Link>
              <Link to="/aitripplanner">AI Trip Planner</Link>
              <Link to="/payment-solutions">Payment Solutions</Link>
            </div>
          )}
        </div>

        {/* Resources Dropdown */}
        <div
          className="navitem_dropdown"
          onMouseEnter={toggleResourcesDropdown}
          onMouseLeave={closeDropdowns}
        >
          <button className="navitem_buttons">Resources</button>
          {showResourcesDropdown && (
            
            <div className="dropdown-content">
              <Link to="/coming-soon" onClick={scrollToTop}>
                Integrations
              </Link>
              <Link to="/coming-soon" onClick={scrollToTop}>
                Onboarding APIs
              </Link>
              <Link to="/coming-soon" onClick={scrollToTop}>
                Developer Docs
              </Link>
              <Link to="/coming-soon" onClick={scrollToTop}>
                API Reference
              </Link>
            </div>
           
          )}
        </div>

        {/* Pricing Link */}
        <Link to="/pricing" onClick={scrollToTop}>
          <button className="navitem_buttons">Pricing</button>
        </Link>
      </div>

      {/* Login Button */}
      <div className="navitem_login">
      <a
                href="https://activitybeds.com/login"
                target="_blank "
                rel="noreferrer"
              >
        <button className="navitem_login_button">Login</button>
        </a>
      </div>
    </div>
  );
};

export default Navebar;
