import React from "react";
import { Link } from "react-router-dom";
import "./ChannelManager.css";
import mainbg from "./mapbase.png";
import flowchart from "./Flowchart.png";
import chart from "./chart.png";
import people from "./people.png";
import ticket from "./ticket-star.png";
import channel from "./channel.png";

const ChannelManager = () => {
  return (
    <>
      <div className="desktop_div">
        <div className="channel_main">
          <div
            className="channel_inner"
            style={{
              backgroundImage: `url(${mainbg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "  ",
              height: "449.751px",
            }}
          >
            <div className="channel_inner_content">
              <h3>Channel Manager</h3>
              <h2>Optimize your distribution strategy with Channel Manager</h2>
              <p>
                Optimize your global reach and maintain full control over your
                business with our integrated channel management platform. From
                inventory to sales and pricing, streamline operations, forge
                partnerships, and automate bookings with ease. Experience
                next-level efficiency and propel your travel business forward
              </p>
            </div>
          </div>
        </div>
        <div className="channel_works">
          <h3>How it works</h3>
          <h2>Understanding channel manager workflow</h2>
          <p>
            Explore step-by-step flowchart to see how our Channel Manager
            optimizes your travel business
          </p>
        </div>
        <div className="channel_image">
          <img src={flowchart} alt="" />
        </div>
        <div className="channel_elevate">
          <h3>our Solutions</h3>
          <h2>Set to Elevate?</h2>
          <p>Discover our success-focused innovations</p>
        </div>
        <div className="channel_mainsquare">
          <div className="channel_square_div csone">
            <div className="channel_square_inner">
              <div className="channel_inner_imgtext">
                <div className="channel_inner_img channel_inner_imgone">
                  <img src={channel} alt="" />
                </div>
                <h3 className="channel_inner_textone">
                  Multichannel Integration
                </h3>
              </div>
              <h2>
                Unified Distribution Solutions: Maximizing Reach, Minimizing
                Complexity
              </h2>
              <p>
                Streamline your distribution strategy and enhance visibility
                with our unified platform. Ensure consitency and efficiency
                across every touch point of your travel business
              </p>
            </div>
          </div>
          <div className="channel_square_div cstwo">
            <div className="channel_square_inner">
              <div className="channel_inner_imgtext">
                <div className="channel_inner_img channel_inner_imgtwo">
                  <img src={people} alt="" />
                </div>
                <h3 className="channel_inner_texttwo">
                  Distribution Partnerships
                </h3>
              </div>
              <h2>
                Strategic Alliance Solutions: Stretching Boundaries, Amplifying
                Results
              </h2>
              <p>
                Forge strategic partnerships to extend your market reach and
                amplify the impact of your travel business
              </p>
            </div>
          </div>
          <div className="channel_square_div csthree">
            <div className="channel_square_inner">
              <div className="channel_inner_imgtext">
                <div className="channel_inner_img channel_inner_imgthree">
                  <img src={ticket} alt="" />
                </div>
                <h3 className="channel_inner_textthree">Booking Automation</h3>
              </div>
              <h2>
                Efficient Booking Solutions: Streamlining Travel Operations
              </h2>
              <p>
                Empower your travel business with our advanced booking
                automation tools, reducing manual workload and optimizing
                efficiency across all operations.
              </p>
            </div>
          </div>
          <div className="channel_square_div csfour">
            <div className="channel_square_inner">
              <div className="channel_inner_imgtext">
                <div className="channel_inner_img channel_inner_imgfour">
                  <img src={chart} alt="" />
                </div>
                <h3 className="channel_inner_textfour">OTA Integration</h3>
              </div>
              <h2>
                Seamless OTA Integration: Expand Your Reach, Amplify Your Impact
              </h2>
              <p>
                Effortlessly connect your travel business with leading online
                travel agencies (OTAs), maximizing visibility and revenue
                opportunities.
              </p>
            </div>
          </div>
        </div>
        <div className="channel_contact_section">
          <div className="channel_contact_inner">
            <div className="channel_contact_inner_top">
              <h2>contact us</h2>
              <h3>Cooperative problem solving: Building better futures</h3>
            </div>
            <div className="channel_contact_inner_bottom">
              <p>
                Ready to enhance your channel management <br></br> strategy?
                Reach out to us now for tailored solutions
              </p>
              <Link to="/contact-us">
              <button>Contact Us</button></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile_div">
        <div
          className="channel_manager_mobile_hero"
          style={{
            backgroundImage: `url(${mainbg})`,
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 0.677px 0.463px 0px",
            display: "flex",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            objectPosition: "center center",
            width: "100%",
            height: "375.387px",
          }}
        >
          <div className="channel_manager_mobile_hero_solutions">
            <h3>Channel Manager</h3>
            <div className="channel_manager_mobile_hero_solutions_text_box">
              <h2>Optimize your distribution strategy with Channel Manager</h2>
              <p>
                Optimize your global reach and maintain full control over your
                business with our integrated channel management platform. From
                inventory to sales and pricing, streamline operations, forge
                partnerships, and automate bookings with ease. Experience
                next-level efficiency and propel your travel business forward
              </p>
            </div>
          </div>
        </div>
        <div className="channel_manager_mobile_hiw">
          <div className="channel_manager_mobile_hiw_services">
            <div className="channel_manager_mobile_hiw_services_inner">
              <h3>How it Works</h3>
              <div className="channel_manager_mobile_hiw_services_inner_text">
                <h2>Understanting channel manager workflow</h2>
                <p>
                  Explore step-by-step flowchart to see how our Channel Manager
                  optimizes your travel business
                </p>
              </div>
            </div>
          </div>
          <div className="channel_manager_mobile_hiw_flowchart">
            <img src={flowchart} alt="" />
          </div>
        </div>
        <div className="channel_manager_mobile_services">
          <div className="channel_manager_mobile_services_solutions">
            <h3>Our Solution</h3>
            <div className="channel_manager_mobile_services_solutions_text_box">
              <h2>Set to Elevate?</h2>
              <p>Discover our success-focused innovations</p>
            </div>
          </div>
          {/* <div className="channel_manager_mobile_services_channel_manager">
            <div className="channel_manager_mobile_services_channel_manager_main_div cmmscmmdone">
              <div className="channel_manager_mobile_services_channel_manager_main_div_left">
                <div className="channel_manager_mobile_services_channel_manager_main_div_left_top">
                  <img src={channel} alt="" />
                  <h3>Multichannel Integration</h3>
                </div>
                <h2>
                  Unified Distribution<br></br> Solutions: Maximizing<br></br>{" "}
                  Reach, Minimizing<br></br>
                  Complexity
                </h2>
                <p>
                  Streamline your distribution strategy and <br></br>enhance
                  visibility with our unified platform. <br></br>Ensure
                  consitency and efficiency across every<br></br> touch point of
                  your travel business
                </p>
              </div>
            </div>
            <div className="channel_manager_mobile_services_channel_manager_main_div cmmscmmdtwo">
              <div className="channel_manager_mobile_services_channel_manager_main_div_left">
                <div className="channel_manager_mobile_services_channel_manager_main_div_left_top">
                  <img src={people} alt="" />
                  <h3>Distribution Partnerships</h3>
                </div>
                <h2>
                Strategic Alliance <br></br>Solutions: <br></br>
                Stretching<br></br> Boundaries,<br></br> Amplifying Results
                </h2>
                <p>
                Forge strategic partnerships to extend<br></br> your market reach and amplify the<br></br> impact of your travel business
                </p>
              </div>
            </div>
            <div className="channel_manager_mobile_services_channel_manager_main_div cmmscmmdthree">
              <div className="channel_manager_mobile_services_channel_manager_main_div_left">
                <div className="channel_manager_mobile_services_channel_manager_main_div_left_top">
                  <img src={ticket} alt="" />
                  <h3>Multichannel Integration</h3>
                </div>
                <h2>
                  Unified Distribution<br></br> Solutions: Maximizing<br></br>{" "}
                  Reach, Minimizing<br></br>
                  Complexity
                </h2>
                <p>
                  Streamline your distribution strategy and <br></br>enhance
                  visibility with our unified platform. <br></br>Ensure
                  consitency and efficiency across every<br></br> touch point of
                  your travel business
                </p>
              </div>
            </div>
            <div className="channel_manager_mobile_services_channel_manager_main_div cmmscmmdfour">
              <div className="channel_manager_mobile_services_channel_manager_main_div_left">
                <div className="channel_manager_mobile_services_channel_manager_main_div_left_top">
                  <img src={chart} alt="" />
                  <h3>Multichannel Integration</h3>
                </div>
                <h2>
                  Unified Distribution<br></br> Solutions: Maximizing<br></br>{" "}
                  Reach, Minimizing<br></br>
                  Complexity
                </h2>
                <p>
                  Streamline your distribution strategy and <br></br>enhance
                  visibility with our unified platform. <br></br>Ensure
                  consitency and efficiency across every<br></br> touch point of
                  your travel business
                </p>
              </div>
            </div>
          </div> */}
          <div className="cmm_mobile_main">
            <div className="cmm_mobile_main_four_div cmm_one">
              <div className="cmm_mobile_main_four_div_left_sec">
                <div className="cmm_mobile_main_four_div_left_sec_top">
                  <img className="cmmffdlst_one" src={channel} alt="" />
                  <h3 className="cmmffdlst_one_text">
                    Multichannel Integration
                  </h3>
                </div>
                <h2>
                  Unified Distribution Solutions: Maximizing Reach, Minimizing
                  Complexity
                </h2>
                <p>
                  Streamline your distribution strategy and enhance visibility
                  with our unified platform. Ensure consitency and efficiency
                  across every touch point of your travel business
                </p>
              </div>
            </div>
            <div className="cmm_mobile_main_four_div cmm_two">
              <div className="cmm_mobile_main_four_div_left_sec">
                <div className="cmm_mobile_main_four_div_left_sec_top">
                  <img className="cmmffdlst_two" src={people} alt="" />
                  <h3 className="cmmffdlst_two_text">
                    Distribution Partnerships
                  </h3>
                </div>
                <h2>
                  Strategic Alliance Solutions: Stretching Boundaries,
                  Amplifying Results
                </h2>
                <p>
                  Forge strategic partnerships to extend your market reach and
                  amplify the impact of your travel business
                </p>
              </div>
            </div>
            <div className="cmm_mobile_main_four_div cmm_three">
              <div className="cmm_mobile_main_four_div_left_sec">
                <div className="cmm_mobile_main_four_div_left_sec_top">
                  <img className="cmmffdlst_three" src={ticket} alt="" />
                  <h3 className="cmmffdlst_three_text">Booking Automation</h3>
                </div>
                <h2>
                  Efficient Booking Solutions: Streamlining Travel Operations
                </h2>
                <p>
                  Empower your travel business with our advanced booking
                  automation tools, reducing manual workload and optimizing
                  efficiency across all operations.
                </p>
              </div>
            </div>
            <div className="cmm_mobile_main_four_div cmm_four">
            <div className="cmm_mobile_main_four_div_left_sec">
                <div className="cmm_mobile_main_four_div_left_sec_top">
                  <img className="cmmffdlst_four" src={chart} alt="" />
                  <h3 className="cmmffdlst_four_text">OTA Integration</h3>
                </div>
                <h2>
                Seamless OTA Integration: Expand Your Reach, Amplify Your Impact
                </h2>
                <p>
                Effortlessly connect your travel business with leading online travel agencies (OTAs), maximizing visibility and revenue opportunities. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="agentmarketplace_mobile_cta">
          <h3>Unlocking Success</h3>
          <h2>
            Unlocking the high-tech frontier of B2B travel ventures: Where
            innovation fuels growth and elevates your journey to unparalleled
            success
          </h2>
          <Link to="/contact-us">
          <button>Contact us</button></Link>
        </div>
      </div>
    </>
  );
};

export default ChannelManager;
