import React from "react";
import { Link } from "react-router-dom";
import "./Agentmarketplace.css";
import one from "./one.svg";
import two from "./two.png";
import three from "./three.png";
import four from "./four.png";
import five from "./five.png";
import six from "./six.png";
import saven from "./saven.png";
import eight from "./eight.png";
import hotel from "./Hotel.png";
import car from "./car.png";
import hand from "./hand.png";
import roller from "./roller.png";
import sone from "./sone.png";
import sbone from "./sbone.svg";
import stwo from "./stwo.png";
import sthree from "./sthree.svg";
import sbtwo from "./sbtwo.svg";
import sbthree from "./sbthree.svg";
import sfour from "./sfour.svg";
import sfive from "./sfive.svg";
import ssix from "./ssix.svg";
import sbfour from "./sbfour.svg";
import sbfive from "./sbfive.svg";
import sbsix from "./sbsix.svg";

const Agentmarketplace = () => {
  return (
    <>
      <div className="desktop_div">
        <div className="Agentmarketplace_main">
          <div className="Agentmarketplace_inner">
            <h3>Agent Marketplace</h3>
            <h2>Agent Marketplace</h2>
            <p>
              Web-based platform leveraging scalable cloud infrastructure with
              microservices for modular <br></br> and efficient functionality.
              The platform fetaures a user login system with Role BAsed Access{" "}
              <br></br> (RBAC), allowing travel agent partners & tour operators
              to manage bookings
            </p>
          </div>
          <div className="Agentmarketplace_square">
            <div className="square_box boxone">
              <img src={one} alt="" />
            </div>
            <div className="square_box boxtwo">
              <img src={two} alt="" />
            </div>
            <div className="square_box boxthree">
              <img src={three} alt="" />
            </div>
            <div className="square_box boxfour">
              <img src={four} alt="" />
            </div>
          </div>
          <div className="Agentmarketplace_square_bottom">
            <div className="square_box boxfive">
              <img src={five} alt="" />
            </div>
            <div className="square_box boxsix">
              <img src={six} alt="" />
            </div>
            <div className="square_box boxsaven">
              <img src={saven} alt="" />
            </div>
            <div className="square_box boxeight">
              <img src={eight} alt="" />
            </div>
          </div>
        </div>
        <div className="usuccess">
          <div className="success_inner">
            <h3>Unlocking Success</h3>
            <h2>
              Unlocking the high-tech frontier of B2B travel ventures:<br></br>Where
              innovation fuels growth and elevates your journey to unparalleled
              success
            </h2>
            <Link to="/contact-us">
            <button>Contact Us</button></Link>
          </div>
        </div>
        <div className="Agentmarketplace_service">
          <div className="Agentmarketplace_service_inner">
            <h3>our services</h3>
            <h2>Tech it and Go</h2>
            <p>Slay the digital highway with our tech-infused experiences</p>
          </div>
        </div>
        <div className="big_four_square">
          <div className="big_four_square_inner">
            <div className="big_four_box">
              <img className="imgone" src={hotel} alt="" />
            </div>
            <div className="big_four_box">
              <img className="imgtwo" src={roller} alt="" />
            </div>
            <div className="big_four_box">
              <img className="imgthree" src={car} alt="" />
            </div>
            <div className="big_four_box">
              <img className="imgfour" src={hand} alt="" />
            </div>
          </div>
        </div>
        <div className="agentmarket_solution">
          <h3>our Solutions</h3>
          <h2>Ready to Make Waves?</h2>
          <p>Dive into our result-driven solutions</p>
        </div>
        <div className="six_box_sec">
          <div className="six_box_sec_top">
            <div className="sixboxtop">
              <img className="simage simageone" src={sone} alt="" />
              <h3>Real Time Availability & Pricing for Hotels & Activities</h3>
              <p>
                Integrate APIs from multiple travel suppliers to access live
                inventory for hotels and attractions
              </p>
              <img className="sbimage" src={sbone} alt="" />
            </div>
            <div className="sixboxtop">
              <img className="simage simagetwo" src={stwo} alt="" />
              <h3>Agents Branding on E-tickets & Vouchers</h3>
              <p>
                Incorporate your details and branding elements in the vouchers &
                e-tickets
              </p>
              <img className="sbimage" src={sbtwo} alt="" />
            </div>
            <div className="sixboxtop">
              <img className="simage simagethree" src={sthree} alt="" />
              <h3>Book now & Pay Later</h3>
              <p>
                Temporarily reserve inventory to guarantee availability until
                the hold expires
              </p>
              <img className="sbimage" src={sbthree} alt="" />
            </div>
          </div>
          <div className="six_box_sec_bottom">
            <div className="sixboxtop">
              <img className="simage simagefour" src={sfour} alt="" />
              <h3>Customer Support Trip App</h3>
              <p>
                This app provides travelers with key information, real-time
                support, and quick issue resolution
              </p>
              <img className="sbimage" src={sbfour} alt="" />
            </div>
            <div className="sixboxtop">
              <img className="simage simagefive" src={sfive} alt="" />
              <h3>Instant Confirmation</h3>
              <p>
                Get immediate confirmations and access to vouchers in just a
                click
              </p>
              <img className="sbimage" src={sbfive} alt="" />
            </div>
            <div className="sixboxtop">
              <img className="simage simagesix" src={ssix} alt="" />
              <h3>Electronic Ticket & Detailed Reports</h3>
              <p>
                Validate QR-coded E-tickets and access all booking and financial
                records in one place
              </p>
              <img className="sbimage" src={sbsix} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="mobile_div">
        <div className="agentmarketplace_mobile_main">
          <div className="agentmarketplace_mobile_hero_main">
            <div className="agentmarketplace_mobile_hero_solution">
              <div className="agentmarketplace_mobile_hero_solution_inner">
                <h3>Agent Marketplace</h3>
                <div className="agentmarketplace_mobile_hero_solution_inner_text_block">
                  <h2>Agent Marketplace</h2>
                  <p>
                    Web-based platform leveraging scalable cloud infrastructure
                    with microservices for modular and efficient functionality.
                    The platform fetaures a user login system with Role BAsed
                    Access (RBAC), allowing travel agent partners & tour
                    operators to manage bookings
                  </p>
                </div>
              </div>
            </div>
            <div className="agentmarketplace_mobile_hero_icons">
              <img
                className="agentmarketplace_mobile_hero_imgs mobileimgone"
                src={one}
                alt=""
              />
              <img
                className="agentmarketplace_mobile_hero_imgs mobileimgtwo"
                src={two}
                alt=""
              />
              <img
                className="agentmarketplace_mobile_hero_imgs mobileimgthree"
                src={three}
                alt=""
              />
              <img
                className="agentmarketplace_mobile_hero_imgs mobileimgfour"
                src={four}
                alt=""
              />
              <img
                className="agentmarketplace_mobile_hero_imgs mobileimgfive"
                src={five}
                alt=""
              />
              <img
                className="agentmarketplace_mobile_hero_imgs mobileimgsix"
                src={six}
                alt=""
              />
              <img
                className="agentmarketplace_mobile_hero_imgs mobileimgsaven"
                src={saven}
                alt=""
              />
              <img
                className="agentmarketplace_mobile_hero_imgs mobileimgeight"
                src={eight}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="agentmarketplace_mobile_cta">
          <h3>Unlocking Success</h3>
          <h2>
            Unlocking the high-tech frontier of B2B travel ventures: Where
            innovation fuels growth and elevates your journey to unparalleled
            success
          </h2>
          <Link to="/contact-us">
          <button>Contact us</button></Link>
        </div>
        <div className="agentmarketplace_mobile_services_main">
          <div className="agentmarketplace_mobile_services_solution">
            <h3>Our Services</h3>
            <div className="agentmarketplace_mobile_services_solution_text_box">
              <h2>Tech it and Go</h2>
              <p>Slay the digital highway with our tech-infused experiences</p>
            </div>
          </div>
          <div className="agentmarketplace_mobile_services_icons">
            <div className="agentmarketplace_mobile_services_image_div">
              <img
                className="agentmarketplace_mobile_services_imagesone"
                src={hotel}
                alt=""
              />
            </div>
            <div className="agentmarketplace_mobile_services_image_div">
              <img
                className="agentmarketplace_mobile_services_imagestwo"
                src={roller}
                alt=""
              />
            </div>
            <div className="agentmarketplace_mobile_services_image_div">
              <img
                className="agentmarketplace_mobile_services_imagesthree"
                src={car}
                alt=""
              />
            </div>
            <div className="agentmarketplace_mobile_services_image_div">
              <img
                className="agentmarketplace_mobile_services_imagesfour"
                src={hand}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="agentmarketplace_mobile_sixbox_services_main">
          <div className="agentmarketplace_mobile_sixbox_services_solution">
            <h3>Our Solutions</h3>
            <div className="agentmarketplace_mobile_sixbox_services_solution_text_box">
              <h2>Ready to Make Waves</h2>
              <p>Dive into our result-driven solutions</p>
            </div>
          </div>
          <div className="agentmarketplace_mobile_sixbox_services_icons">
            <div className="agentmarketplace_mobile_sixbox_services_icons_main_divs">
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgone"
                src={sone}
                alt=""
              />
              <h2>Real Time Availability & Pricing for Hotels & Activities</h2>
              <p>
                Integrate APIs from multiple travel suppliers to access live
                inventory for hotels and attractions
              </p>
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgoneb"
                src={sbone}
                alt=""
              />
            </div>
            <div className="agentmarketplace_mobile_sixbox_services_icons_main_divs">
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgtwo"
                src={stwo}
                alt=""
              />
              <h2>Agents Branding on E-tickets & Vouchers</h2>
              <p>
                Incorporate your details and branding elements in the vouchers &
                e-tickets
              </p>
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgoneb"
                src={sbtwo}
                alt=""
              />
            </div>
            <div className="agentmarketplace_mobile_sixbox_services_icons_main_divs">
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgthree"
                src={sthree}
                alt=""
              />
              <h2>Book now & Pay Later</h2>
              <p>
                Temporarily reserve inventory to guarantee availability until
                the hold expires
              </p>
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgoneb"
                src={sbthree}
                alt=""
              />
            </div>
            <div className="agentmarketplace_mobile_sixbox_services_icons_main_divs">
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgtfour"
                src={sfour}
                alt=""
              />
              <h2>Customer Support Trip App</h2>
              <p>
                This app provides travelers with key information, real-time
                support, and quick issue resolution
              </p>
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgoneb"
                src={sbfour}
                alt=""
              />
            </div>
            <div className="agentmarketplace_mobile_sixbox_services_icons_main_divs">
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgtfive"
                src={sfive}
                alt=""
              />
              <h2>Instant Confirmation</h2>
              <p>
                Get immediate confirmations and access to vouchers in just a
                click
              </p>
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgoneb"
                src={sbfive}
                alt=""
              />
            </div>
            <div className="agentmarketplace_mobile_sixbox_services_icons_main_divs">
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgtsix"
                src={ssix}
                alt=""
              />
              <h2>Electronic Ticket & Detailed Reports</h2>
              <p>
                Validate QR-coded E-tickets and access all booking and financial
                records in one place
              </p>
              <img
                className="agentmarketplace_mobile_sixbox_services_icons_main_divs_imgoneb"
                src={sbsix}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Agentmarketplace;
