import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import logo from "./activitybedlogo.png";
import insta from "./insta.svg";
import twitter from "./twitter.png";
import facebook from "./facebook.svg";
import linkedin from "./linkedin.png";
import youtube from "./youtube.png";
import "./Footor.css";

const Footor = () => {
  const location = useLocation();
  const [isIndia, setIsIndia] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get(
          "https://ipinfo.io/json?token=f6a7bdb81783b0"
        );
        if (response.data && response.data.country === "IN") {
          setIsIndia(true);
        }
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    fetchLocation();
  }, []);

  return (
    <div className="footor_main">
      <div className="footor_top">
        <div className="footor_top_logo">
          <Link to="/">
            <img src={logo} alt="Activitybeds logo" />
          </Link>
          <p>
            High-Tech Horizons: Where <br /> innovation fuels your business
          </p>
        </div>
        <div className="footor_top_links">
          <h2>Solutions</h2>
          <Link to="/agent-marketplace">
            <h3>Agent Marketplace</h3>
          </Link>
          <Link to="/channel-manager">
            <h3>Channel Manager</h3>
          </Link>
          <Link to="/aitripplanner">
            <h3>AI Trip Planner</h3>
          </Link>
          <Link to="/payment-solutions">
            <h3>Payment Solutions</h3>
          </Link>
        </div>
        <div className="footor_top_links">
          <h2>Resources</h2>
          <Link to="/coming-soon">
            <h3>Integrations</h3>
          </Link>
          <Link to="/coming-soon">
            <h3>Onboarding APIs</h3>
          </Link>
          <Link to="/coming-soon">
            <h3>Developer Docs</h3>
          </Link>
          <Link to="/coming-soon">
            <h3>API Reference</h3>
          </Link>
        </div>
        <div className="footor_top_links">
          <h2>Company</h2>
          <Link to="/about-us">
            <h3>About Us</h3>
          </Link>
          <Link to="/contact-us">
            <h3>Contact Us</h3>
          </Link>
          <Link to="/what-we-do">
            <h3>What we do</h3>
          </Link>
          <a
            href="https://activitybeds.freshdesk.com/support/home"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>FAQ & Support Center</h3>
          </a>
        </div>
        <div className="footor_top_social">
          <h2>Follow us</h2>
          <div className="footor_top_social_icons">
            <a
              href="https://www.instagram.com/activitybeds/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={insta} alt="Instagram" />
            </a>
            <a
              href="https://twitter.com/activitybeds"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Twitter" />
            </a>
            <a
              href="https://www.facebook.com/ActivityBeds-103917885505349"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" />
            </a>
          </div>
          <div className="footor_top_social_icons_bottom">
            <a
              href="https://www.linkedin.com/company/activitybeds/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="LinkedIn" />
            </a>
            <a
              href="https://www.youtube.com/channel/UC3dGUTfjlkze7K41bATa3HA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="YouTube" />
            </a>
          </div>
        </div>
      </div>
      <div className="footor_bottom">
        <hr className="horizontal-line" />
        <div className="footor_bottom_inner">
          <div className="footor_bottom_inner_text">
            <p>
              Copyright © 2024 Activitybeds Technologies{" "}
              {isIndia ? "PVT LTD" : "LLC"}. All rights reserved.
            </p>
          </div>
          <div className="footor_bottom_inner_links">
            <Link to="/privacy-policy">
              <h3>Privacy Policy</h3>
            </Link>
            <Link to="/terms-and-conditions">
              <h3>Terms & Conditions</h3>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footor;
