import React from "react";
import "../PTW.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="ptw_top_section">
        <h2>Privacy Policy</h2>
        <p>
          Your privacy is our priority; we safeguard your personal information
          with the highest standards of security and transparency.
        </p>
      </div>
      <div className="ptw_content_outer">
        <div className="ptw_content_inner">
          <h2>Introduction</h2>
          <div className="ptw_content_texts">
            <p>
              We know that sharing your personal information with us is based on
              trust. We take this seriously and are committed to ensuring that
              we respect your privacy when you visit our website or use our
              services. Please review this privacy and cookies statement
              (“Statement”) carefully to learn about our privacy practices.
            </p>
            <p>
              We operate a service that enables Travel Partners (“you”, “your”)
              to research, find, and book travel experiences worldwide. In this
              Statement, these are collectively referred to as our Services.
            </p>
            <p>
              Our Services are owned and operated by Activitybeds Technologies,
              LLC. By using our Services and visiting our websites and related
              applications, whether on a computer, phone, tablet or similar
              device (these are all referred to as a “Device”), you are
              accepting the practices described below.
            </p>
            <p>
              As a global company, we operate in a number of countries and
              territories where the laws and customs differ. This Statement
              provides a general overview of our privacy practices. This is
              evident in the ways we manage personal information of our
              suppliers / travel trade partners and their customers. Besides
              that, we also manage the information of the individuals who work
              for, (or on behalf) of our suppliers / travel trade partners. We
              are transparent in how we collect and use personal information and
              respect privacy.
            </p>
            <p>
              This privacy policy determines how we collect, use and process
              personal information while performing our contractual obligations
              and managing relationships in B2B ecosystem. This policy does not
              apply on the information collection of our B2B partners during the
              course of their business relationship (that includes the personal
              information of their customers). We are always happy to answer any
              questions you may have, or to provide you with any additional
              information that you may need. You can reach out to us at via FAQ
              & support center section.
            </p>
            <p>
              We review this Privacy Notice regularly to ensure that we are
              being transparent about how we use your personal information. Any
              changes to this Privacy Notice will be reflected on our website
              and will take effect on the date of publication.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Who we are?</h2>
          <div className="ptw_content_texts">
            <p>
              Activitybeds source content from travel suppliers, connect,
              aggregate and present that content on the platform, and distribute
              it to global network of travel trade partners, who sell it to
              their customers.
            </p>
            <p>
              For the purposes of the General Data Protection Regulation
              2016/679 (“GDPR”), and to the extent that we process personal
              information of our B2B travel trade partners, who are an
              independent companies (jurisdiction wise). Any personal
              information we collect and process, enable us to manage our
              business relationship with our suppliers / travel trade partners
              and perform our business functions including:<br></br> Booking of
              activities/transfers and accommodation services.<br></br>{" "}
              Facilitation of payments for activities/transfers and
              accommodation services. Marketing our products & services.
              <br></br> Compliance with procedures and legal obligations
              globally.
            </p>
            <p>
              We are firmly committed to ensuring the privacy of the personal
              information we collect and to maintaining safeguards to protect
              personal information in our care. There may be instances where
              specific country local data protection laws impose more
              restrictive information handling practices than the practices set
              out in this Privacy Notice. Where this occurs, we will adjust our
              information handling practices in that specific country
              jurisdiction, to comply with these local data protection laws.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Types of Personal information and how do we collect it?</h2>
          <div className="ptw_content_texts">
            <p>
              We may need to collect certain types of personal information from
              our suppliers / travel trade partners, to provide access to our
              services, to manage our ongoing business relationships and to
              fulfill our legal and contractual obligations. Some of the
              information we collect such as: Business contact information
              (Including business contact name and business contact details)
              Business payment and billing information, Information of our B2B
              partners customers (including Name , Address ,Date of birth
              ,Passport number ,Email address, Tax ID, Phone/WhatsApp number).
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>How do we use this information?</h2>
          <div className="ptw_content_texts">
            <p>
              We use the information for the fulfillment of our business
              contract & marketing such as while booking of activities and
              accommodation services. We do not share this information except to
              the extent necessary to complete that order. To process and
              facilitate transactions and payments To occasionally inform our
              marketing campaigns and promotions. However, within each email
              there is an option to opt out from receiving such emails in the
              future. To communicate with our suppliers / travel trade partners
              about the Services For research and development To manage our
              business relationships To comply with our legal obligations,
              policies and procedures
            </p>
            <p>
              Finally, we never use or share the personally identifiable
              information provided to us online in ways unrelated to the ones
              described above without also providing you with an opportunity to
              opt-out or otherwise prohibit such unrelated uses.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Types of Personal information and how do we collect it?</h2>
          <div className="ptw_content_texts">
            <p>
              We may need to collect certain types of personal information from
              our suppliers / travel trade partners, to provide access to our
              services, to manage our ongoing business relationships and to
              fulfill our legal and contractual obligations. Some of the
              information we collect such as: Business contact information
              (Including business contact name and business contact details)
              Business payment and billing information, Information of our B2B
              partners customers (including Name , Address ,Date of birth
              ,Passport number ,Email address, Tax ID, Phone/WhatsApp number).
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>How do we share the personal information we collect?</h2>
          <div className="ptw_content_texts">
            <p>
              We will only share personal information we collect with a third
              party if it is necessary for us to provide our services. We have
              contractual provisions in place with all third parties we share
              personal information with, to ensure they handle this information
              with care, comply with all applicable laws and do not use this
              personal information for any other purpose.
            </p>
            <p>
              In exceptional circumstances, we may share personal information we
              have collected with a third party if we believe that sharing is
              reasonably necessary to: Comply with any applicable law,
              regulation, legal process or governmental request, including
              meeting national security requirements. Enforce our agreements,
              policies and terms of service. Protect Activitybeds, our customers
              or the public from harm or illegal activities; or Respond to an
              emergency which we believe in good faith requires us to disclose
              information.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Disclosure of information outside the EEA</h2>
          <div className="ptw_content_texts">
            <p>
              In order to continue providing our Services, we may need to
              disclose information to recipients in other jurisdictions,
              including to our related entities, our suppliers, or our
              third-party service providers .To the extent that such a
              disclosure includes the personal information of our suppliers /
              travel trade partners located in the EEA, we implement adequate or
              appropriate safeguards to ensure it is properly protected.
            </p>
            <p>
              In dealing with transfers from the EEA (or the UK, as applicable)
              to countries that have not been approved by the European
              Commission, we will rely on the EU Commission-approved Standard
              Contractual Clauses (SCC’s) to process your personal information
              in order to provide the Services, to safeguard the transfer of
              your personal information.
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>How long we keep your personal information?</h2>
          <div className="ptw_content_texts">
            <p>
              Information we collect from our suppliers / travel trade partners
              will be retained for the duration of our contractual relationship,
              and for a reasonable period thereafter. We retain this information
              as reasonably necessary to:
            </p>
            <p>
              Maintain our business records (for analysis, security, tax and/or
              audit purposes); Handle any complaints or disputes regarding the
              Services; Comply with our legal obligations and protect or defend
              our legal rights; Enforce our contractual obligations and
              agreements
            </p>
          </div>
        </div>
        <div className="ptw_content_inner">
          <h2>Changes to our Privacy Policy</h2>
          <div className="ptw_content_texts">
            <p>
              Our policies may be changed or updated occasionally to meet the
              industry and regulatory requirements and standards. Therefore, the
              suppliers / travel trade partners are encouraged to frequently
              visit these sections in order to be updated about the changes on
              the website. Modifications will be effective on and from the day
              they are posted.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
